import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Grid, Dialog, Box, Card, DialogActions, CardContent, CardActions, DialogContent, DialogTitle, TableContainer, Paper, Table, TableBody, TableRow, TableCell, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { getInstruments, getAccessTokenn, saveAccessToken } from '../indexDB';

interface Ohlc {
  open: number;
  high: number;
  low: number;
  close: number;
}
interface TradingData {
  instrument_token: number;
  tradingsymbol: string;
  timestamp: string;
  last_price: number;
  net_change: number;
  ohlc: Ohlc;
}
interface ApiResponse {
  status: string;
  data: Record<string, TradingData>;
}
interface Instrument {
  instrument_token: number;
  tradingsymbol: string;
  timestamp: string;
  last_price: number;
  net_change: number;
  ohlc: Ohlc;
}

interface Data {
  [key: string]: Instrument;
}
interface MarketSegment {
  key: number;
  text: string;
  value: string;
}
interface DataCardProps {
  data?: Data; // Make `data` optional
  instrumentToken: number;
}
const Quote = () => {
  const [userID, setUserID] = useState('');
  const [strikePrices, setStrikePrices] = useState<{ [key: number]: string[] }>({});
  const [instrumentList, setInstrumentList] = useState<any[]>([]);
  const hasFetchedRef = useRef(false);

  const [symbolIDX, setSymbolIDX] = useState<string[]>([]);
  const [symbolSTK, setSymbolSTK] = useState<string[]>([]);
  const [symbolEQ, setSymbolEQ] = useState<string[]>([]);

  const [mktSegment_FO, setMktSegment_FO] = useState<MarketSegment[]>([]);
  const [mktContractType, setContractType] = useState<MarketSegment[]>([]);

  const [exchange, setExchange] = useState([
    { key: 1, text: 'BSE', value: 'BSE' },
    { key: 2, text: 'BFO', value: 'BFO' },
    { key: 3, text: 'NSE', value: 'NSE' },
    { key: 4, text: 'NFO', value: 'NFO' },
    { key: 5, text: 'MCX', value: 'MCX' }
  ]);
  const [exchangeSelect, setExchangeSelect] = useState("");
  const [mktContractTypeSelect, setContractTypeSelect] = useState("");
  const [mktSegmentSelect, setmktSegmentSelect] = useState("");
  const [symbolSelect, setSymbolSelect] = useState("");
  const [instrument, setInstrumentSelect] = useState(0);
  const [mainData,setMainData] = useState<ApiResponse | undefined>();

  const symbolDropDownFilter = (Type: string, Exchange: string) => {
    let response: any = "";
    if (instrumentList.length > 0) {
      if (Type === "EQ") {
        const startsWithNumberRegex = /^\d/;
        response = instrumentList.filter(x =>
          x.exchange === Exchange &&
          x.segment === Exchange &&
          x.instrument_type === "EQ" &&
          x.name &&
          !x.name.includes("%") &&
          !startsWithNumberRegex.test(x.tradingsymbol)
        );
      }
      else if (Type === "STK") {
        response = instrumentList
          .filter(x =>
            x.exchange === Exchange &&
            x.instrument_type === "FUT" &&
            !x.name.includes("NIFTY") && !x.name.includes("BANKEX") && !x.name.includes("SENSEX") && !x.name.includes("SENSEX50") 
          )
          .map(x => x.name.replace(/"/g, '').trim())
          .filter((value, index, self) => self.indexOf(value) === index);
      }
      else if (Type === "IDX") {
        response = instrumentList
          .filter(x =>
            x.exchange === Exchange &&
            (x.instrument_type === "FUT") &&
           ( x.name.includes("NIFTY") || x.name.includes("BANKEX") || x.name.includes("SENSEX") || x.name.includes("SENSEX50") )
          )
          .map(x => x.name.replace(/"/g, '').trim())
          .filter((value, index, self) => self.indexOf(value) === index);
      }
    }
    return response;
  };
  useEffect(() => {
    if (!hasFetchedRef.current) {
      const fetchData = async () => {
        try {
          const storedData: string = await getInstruments();
          const lines = storedData.trim().split('\n');
          const headers = lines[0].split(',');
          hasFetchedRef.current = true;
          const parsedData = await Promise.all(
            lines.slice(1).map(async line => {
              const values = line.split(',');
              const instrument: any = {};
              headers.forEach((header, index) => {
                instrument[header] = values[index];
              });
              return instrument;
            })
          );
          setInstrumentList(parsedData);
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
          } else {
            console.error('Error fetching data:', error);
          }
        }
      };
      fetchData();
      // Cleanup function
      return () => {
        console.log('Performing cleanup operations...');
      };
    }
  }, []);

  
  const DataCard : React.FC<DataCardProps> = ({  data , instrumentToken }) => {

    if (!data || Object.keys(data).length === 0) {
      return <p>No data available</p>; // Handle when no data is available
    }
    if(!data[instrumentToken]){
      return <p>No data available</p>; 
    }
    const instrument = Object.keys(data).length === 0 ?  jsonData.data["BSE:BANKEX"] : data[instrumentToken];
    const { instrument_token, tradingsymbol, timestamp, last_price, net_change, ohlc } = instrument;

    return (
      <Card sx={{ maxWidth: 600, margin: "20px auto", padding: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {symbolSelect} ({instrument_token})
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Timestamp: {timestamp}
          </Typography>
          <Typography variant="h6">Last Price: ₹{last_price.toFixed(2)}</Typography>
          <Typography variant="body2" color="text.secondary">
            Net Change: {net_change}
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
            OHLC Data:
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {Object.entries(ohlc).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{key.toUpperCase()}</TableCell>
                    <TableCell align="right">₹{(value as number).toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    );
  };
  const jsonData = {
    status: "success",
    data: {
      "BSE:BANKEX": {
        instrument_token: 274441,
        tradingsymbol: "BANKEX",
        timestamp: "1970-01-01 05:30:00",
        last_price: 57276.81,
        net_change: 0,
        ohlc: {
          open: 57181.69,
          high: 57724.13,
          low: 57017.09,
          close: 57199.17,
        },
      },
    },
  };
  const handleExchangeChange = (event: any) => {
    setExchangeSelect(event.target.value);
    if (event.target.value.includes("FO") || event.target.value.includes("MCX")) {
      setMktSegment_FO([{ key: 1, text: 'FUT', value: 'FUT' }, { key: 2, text: 'OPT', value: 'OPT' }]);
      setContractType([{ key: 1, text: 'FUTIDX', value: 'FUTIDX' }, { key: 2, text: 'FUTSTK', value: 'FUTSTK' }]);
      setmktSegmentSelect("FUT");
    } else {
      setMktSegment_FO([{ key: 1, text: 'EQ', value: 'EQ' }]);
      setmktSegmentSelect("EQ");
      const data = symbolDropDownFilter("EQ", event.target.value);
      const filteredSymbol = data.map((item: any) => item.tradingsymbol);
      setSymbolEQ(filteredSymbol);
    }
  };
  const handleMarketSegmentChange = (event: any) => {
    setmktSegmentSelect(event.target.value);
    if (event.target.value.includes("FUT")) {
      setContractType([{ key: 1, text: 'FUTIDX', value: 'FUTIDX' }, { key: 2, text: 'FUTSTK', value: 'FUTSTK' }]);
    } else {
      setContractType([{ key: 1, text: 'OPTIDX', value: 'OPTIDX' }, { key: 2, text: 'OPTSTK', value: 'OPTSTK' }]);
    }
  };
  const handleContractTypeChange = async (event: any) => {
    if (event.target.value.includes("IDX")) {
      const data = symbolDropDownFilter("IDX", exchangeSelect);
      setSymbolIDX(data);
    } else {
      const data = symbolDropDownFilter("STK", exchangeSelect);
      setSymbolSTK(data);
    }
    setContractTypeSelect(event.target.value);
  };
  const handleSymbolChange = async (event: any) => {
    setSymbolSelect(event.target.value);
    let instrument_token = 0;
    if(mktSegmentSelect != "EQ"){
      instrument_token = instrumentList.filter(x=>x.name.replace(/"/g, '').trim() === event.target.value 
                        && x.exchange == exchangeSelect 
                        && x.instrument_type == mktSegmentSelect)[0].instrument_token
    }else{
      instrument_token = instrumentList.filter(x=>x.name.replace(/"/g, '').trim() === event.target.value 
                        && x.exchange == exchangeSelect 
                        && x.instrument_type == "EQ")[0].instrument_token
    }


    setInstrumentSelect(instrument_token);

    const userDetails = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails") || "") : {};
    const {accesstoken,apikey} = userDetails;
    const quote = await axios.get<ApiResponse>('https://api.insperoninvestmentsolutions.com/api/quote', {
      params: {  apikey, accesstoken ,instrument_token}, // Use the 'params' property for query parameters
    });
 
   // console.log(quote)
    setMainData(quote.data)
  };



  return (
    <div>
      {/* Add New Button - Positioned to the right */}
      <CardActions sx={{ flex: 1, justifyContent: 'flex-end', padding: 2, gap: 2 }}>
        {/* Dropdown 1 */}
        <Typography variant="h6" gutterBottom>Exchange</Typography>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="dropdown1-label">Select Exchange</InputLabel>
          <Select
            labelId="dropdown1-label"
            value={exchangeSelect}
            onChange={handleExchangeChange}
            label="Exchange"
          >
            {exchange.map((segment: any) => (
              <MenuItem key={segment.key} value={segment.value}>
                {segment.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h6" gutterBottom>Market Segment</Typography>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="dropdown1-label">Select Market Segment</InputLabel>
          <Select
            labelId="dropdown1-label"
            value={mktSegmentSelect}
            onChange={handleMarketSegmentChange}
            label="Market Segment"
          >
            {mktSegment_FO.map((segment: any) => (
              <MenuItem key={segment.key} value={segment.value}>
                {segment.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography variant="h6" gutterBottom>Contract Type</Typography>
        <FormControl variant="outlined" disabled={mktSegmentSelect == "EQ" ? true : false} size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="dropdown1-label">Select Contract Type</InputLabel>
          <Select
            labelId="dropdown1-label"
            value={mktContractTypeSelect}
            onChange={handleContractTypeChange}
            label="Contract Type"
          >
            {mktContractType.map((segment: any) => (
              <MenuItem key={segment.key} value={segment.value}>
                {segment.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>



        <Typography variant="h6" gutterBottom>
          Select Symbol
        </Typography>
        {/* Dropdown 2 */}
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="dropdown2-label">Select Symbol</InputLabel>
          <Select
            labelId="dropdown2-label"
            value={symbolSelect}
            onChange={handleSymbolChange}
            label="Select Symbol"
          >
            {(mktContractTypeSelect.includes("IDX") ? symbolIDX : mktContractTypeSelect.includes("STK") ? symbolSTK : symbolEQ).map((segment: any) => (
              <MenuItem key={segment} value={segment}>
                {segment}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardActions>

      {/* Conditional rendering for the form */}

      <DataCard  data={mainData ? mainData.data : {}} instrumentToken={instrument} />
    </div>
  )
}


export default Quote;
