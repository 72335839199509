// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.vertical-divider {
  border-left: 2px solid #ccc;
  height: 450px;
  margin-top: 30px;
  /* height: 96%; */
  margin-left: 60px;
  margin-right: 10px;
}
.css-nl63yj {
  padding: calc(0* var(--mui-spacing));
}
.Regenerate{
  text-align: right !important;
  margin-top: 15px;
}

@media (min-width: 1200px) {
  .css-1no3677 {
      max-width: 100% !important;
  }
}
.css-lbcm5a-MuiButtonBase-root-MuiCheckbox-root {
  padding: 1px;
  margin: 0px 10px 0px 0px;
  color: black;
  border : 0
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1edbl1u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 65px;
  height: 2.7em;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,2BAA2B;EAC3B,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,oCAAoC;AACtC;AACA;EACE,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE;MACI,0BAA0B;EAC9B;AACF;AACA;EACE,YAAY;EACZ,wBAAwB;EACxB,YAAY;EACZ;AACF;AACA;EACE,mBAAmB;EACnB,aAAa;AACf","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n.vertical-divider {\n  border-left: 2px solid #ccc;\n  height: 450px;\n  margin-top: 30px;\n  /* height: 96%; */\n  margin-left: 60px;\n  margin-right: 10px;\n}\n.css-nl63yj {\n  padding: calc(0* var(--mui-spacing));\n}\n.Regenerate{\n  text-align: right !important;\n  margin-top: 15px;\n}\n\n@media (min-width: 1200px) {\n  .css-1no3677 {\n      max-width: 100% !important;\n  }\n}\n.css-lbcm5a-MuiButtonBase-root-MuiCheckbox-root {\n  padding: 1px;\n  margin: 0px 10px 0px 0px;\n  color: black;\n  border : 0\n}\n.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1edbl1u-MuiAutocomplete-root .MuiOutlinedInput-root {\n  padding-right: 65px;\n  height: 2.7em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
