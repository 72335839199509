import React, { useState,useEffect, useRef } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Grid, Dialog, Box, Card,DialogActions,CardContent,CardActions, DialogContent, DialogTitle } from '@mui/material';
import { Link } from 'react-router-dom';

const Login = () => {
  const [userID, setUserID] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [TOTPKey, setTOTPKey] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [error, setError] = useState('');
  const [showNewCard, setShowNewCard] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const hasFetchedRef = useRef(false);
  const handleAddNew = () => {
    // Reset form fields and show the empty card
    setNewUsername('');
    setNewPassword('');
    setApiKey('');
    setSecretKey('');
    setTOTPKey('');
    setError('');
    setShowNewCard(true);  // Show the empty form
  };
  const validateForm = () => {
    // Check if all fields are filled
    setIsFormValid(
      newUsername !== '' &&
      newPassword !== '' &&
      apiKey !== '' &&
      secretKey !== '' &&
      TOTPKey !== ''
    );
  };
  useEffect(() => {
    validateForm();
  }, [newUsername, newPassword, apiKey, secretKey, TOTPKey]);
  // Add type annotation for the event parameter 'e' as React.FormEvent<HTMLFormElement>
  const handleRegister = async (ID:any) => {
   let _id= ID == "" ? 0 : ID;
    try {
      const responseByID = await axios.get('https://api.insperoninvestmentsolutions.com/api/getUserDataByID', {
        params: { _id }, // Use the 'params' property for query parameters
      });
      if(responseByID.data.length > 0)
      {
        await axios.post('https://api.insperoninvestmentsolutions.com/api/updateUser', { newUsername, newPassword, apiKey, secretKey, TOTPKey ,ID});
        alert("Updated");
      }else{
        await axios.post('https://api.insperoninvestmentsolutions.com/api/setUserData', { newUsername, newPassword, apiKey, secretKey, TOTPKey });
        alert("Added");
      }
      
    } catch (err) {
      setError("Error during registration.");
    }
  };

  useEffect(() => {
    if (!hasFetchedRef.current) {
      const source = axios.CancelToken.source(); // Create a cancel token for the request
      const fetchData = async () => {
        
        const response = await axios.get('https://api.insperoninvestmentsolutions.com/api/getUserData');
        console.log(response);
        setNewUsername(response.data[0].username);
        setNewPassword(response.data[0].password);
        setApiKey(response.data[0].apikey);
        setSecretKey(response.data[0].secretkey);
        setTOTPKey(response.data[0].totpkey);
        setAccessToken(response.data[0].accesstoken);
        setUserID(response.data[0].id)
        localStorage.setItem("userDetails",JSON.stringify(response.data[0]));
      };
      fetchData();
      // Cleanup function
      return () => {
        source.cancel('Operation canceled by the user.'); // Cancel the request if the component unmounts
        console.log('Performing cleanup operations...');
      };
    }
  }, []);
  const onLoginClick = async (ID:any) =>{
    try {
      const getTokenResponse = await axios.post('https://api.insperoninvestmentsolutions.com/api/getAccessToken',{ newUsername, newPassword, apiKey, secretKey, TOTPKey });
      localStorage.setItem("accessToken",getTokenResponse.data.accessToken);
      const updateResponse = await axios.post('https://api.insperoninvestmentsolutions.com/api/updateAccessToken', { accessToken:getTokenResponse.data.accessToken, id : ID });
      console.log('Update Access Token Response:', updateResponse.data);

      // Retrieve data from IndexedDB
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
    {/* Add New Button - Positioned to the right */}
    <CardActions sx={{ flex: 1, justifyContent: 'flex-end', padding: 2 }}>
      <Button
        type="button"
        variant="contained"
        color="primary"
        sx={{ textAlign: 'right' }}
        onClick={handleAddNew}
      >
        Add New
      </Button>
    </CardActions>
  
    {/* Conditional rendering for the form */}

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={14} sm={12} md={8}>
          <Card sx={{ width: '100%', maxWidth: 1900 }}>
            <CardContent>
              <Typography variant="h5" sx={{ textAlign: 'left', marginBottom: 2 }}>
                {'Login'}
              </Typography>
  
              {/* Display error message if it exists */}
              {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
  
              {/* Login Form */}
              
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                  {/* Individual form fields */}
                  <Box flex={1} minWidth={250}>
                    <TextField
                      label="Username"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={newUsername}
                      onChange={(e) => setNewUsername(e.target.value)}
                    />
                  </Box>
                  <Box flex={1} minWidth={250}>
                    <TextField
                      label="Password"
                      type="password"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </Box>
                  <Box flex={1} minWidth={250}>
                    <TextField
                      label="API Key"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                    />
                  </Box>
                  <Box flex={1} minWidth={250}>
                    <TextField
                      label="Secret Key"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={secretKey}
                      onChange={(e) => setSecretKey(e.target.value)}
                    />
                  </Box>
                  <Box flex={1} minWidth={250}>
                    <TextField
                      label="TOTP Key"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={TOTPKey}
                      onChange={(e) => setTOTPKey(e.target.value)}
                    />
                  </Box>
                  <Box flex={1} minWidth={250}>
                    <TextField
                      label="Access Token"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={accessToken}
                      disabled={true}
                      //onChange={(e) => setAccessToken(e.target.value)}
                    />
                  </Box>
                  </Box>
                  <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  gap={2}
                  justifyContent="flex-end" // Align buttons to the right
                >
                  <Box>
                    <CardActions>
                      <Button
                        type="submit"
                        fullWidth={false} // Ensure button does not take full width
                        variant="contained"
                        color="success"
                        disabled={!isFormValid} // Disable if form is not valid
                        onClick={() => onLoginClick(userID)}
                      >
                        Login
                      </Button>
                    </CardActions>
                  </Box>

                  <Box>
                    <CardActions>
                      <Button
                        type="button"
                        fullWidth={false} // Ensure button does not take full width
                        variant="contained"
                        onClick={()=>handleRegister(userID)}
                        color="secondary"
                      >
                        Update
                      </Button>
                    </CardActions>
                  </Box>
                </Box>

        
            </CardContent>
          </Card>
        </Grid>
      </Grid>
   
  </div>
  )
}
  

export default Login;
