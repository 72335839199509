import React, { useState, useEffect, useRef  } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  IconButton,
  TextField,
  Paper,
  FormControl, InputLabel, Select, MenuItem, Modal, Tab, Tabs
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { getInstruments } from '../indexDB';
import axios from "axios";

const Watchlist = () => {
  const [filters, setFilters] = useState<any>({
    upSideFilters : [],
    downSideFilters: [],
  });
  const [timerStarted, setTimerStarted] = useState(false);
  const [parsedData, setParsedData] = useState<any[]>([]);
  const [pointsMoved, setPointsMoved] = useState(0);
  const [spotLTPDiff, setSpotLTPDiff] = useState(0);
  const [interval, setInterval] = useState(0);
  const [pdVwapDays, setPdVwapDays] = useState<any>({});
  const [hideDropdowns, setHideDropdowns] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [watchLists, setWatchLists] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState(parsedData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newWatchlistName, setNewWatchlistName] = useState("");
  const [fileName, setFileName] = useState<string>(''); 
  const [instrumentList, setInstrumentList] = useState<any[]>([]);
  const hasFetchedRef = useRef(false);
  let ws: WebSocket | null = null;
  let MarketDataList : any[] = [];
  interface WebSocketData {
    ticks: Array<{
      instrument_token: number;
      name: string;
      last_price: number;
      volume_traded: number,
      ohlc: {
        close: any;
        open: number;
        high: any,
        low: any,
      };
    }>;
  }

  const handleImportFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    
    if (file) {
      setFileName(file.name);
  
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
  
      if (fileExtension === 'csv') {
        handleCSVImport(file);
      } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        handleXLSXImport(file);
      } else {
        console.error('Unsupported file type');
        return;
      }
    }
  };
  
  const handleCSVImport = (file: File) => {
    Papa.parse(file, {
      complete: (result) => {
        const data = result.data.map((row: any) => row); // Optional transformation of rows
        setParsedData(data);
      },
      error: (error) => {
        console.error('Error parsing CSV file:', error.message);
      },
      header: true,
      skipEmptyLines: true,
      quoteChar: '"',
      delimiter: ',',
    });
  };
  
  const handleXLSXImport = (file: File) => {
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const data = e.target?.result;
  
      if (data) {
        try {
          // Reading the XLSX file from the array buffer
          const workbook = XLSX.read(data, { type: 'array' });
  
          // Log workbook to see if it's correctly parsed
          console.log('Workbook:', workbook);
  
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
  
          // Log the sheet to see if it's loaded correctly
          console.log('Sheet:', sheet);
  
          if (sheet) {
            // Convert sheet to JSON data
            const jsonData = XLSX.utils.sheet_to_json(sheet);
  
            // Log the parsed data
            console.log('Parsed JSON Data:', jsonData);
  
            // If data is valid, set it
            setParsedData(jsonData);
          } else {
            console.error('No sheet found in workbook');
          }
  
        } catch (err) {
          console.error('Error processing XLSX file:', err);
        }
      } else {
        console.error('No data available in file reader');
      }
    };
  
    reader.onerror = (error) => {
      console.error('Error reading XLSX file:', error);
    };
  
    // Read file as array buffer
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (instrumentList.length > 0 && parsedData.length > 0) {
      const symbols = parsedData.map(data => data.Symbol);
  
      parsedData.forEach(row => {
        // Trim symbol and remove quotes from the tradingsymbol to ensure consistent matching
        const symbol = row.Symbol.trim().replace(/"/g, '');
        const matchingInstrument = instrumentList.find(x =>
          x.exchange === "NSE" &&
          x.instrument_type === "EQ" &&
          symbols.includes(symbol) &&  // Check if row.Symbol is in the symbols list
          x.tradingsymbol.replace(/"/g, '').trim() === symbol &&  // Ensure exact match for symbol
          !x.name.includes("%")
        );
  
        if (matchingInstrument) {
          row.instrumentToken = matchingInstrument.instrument_token; // Bind the instrument token to the row
        }
      });
    }
  }, [parsedData, instrumentList]);

  const handleStartStopTimer = () => {
    setTimerStarted(!timerStarted);
  };

  const handleApplyFilter = () => {
    setHideDropdowns(true);
  };

  const handleCheckboxChange = (filterType:any, filterName:any) => {
    setFilters((prevFilters:any) => {
      const newFilters : any = { ...prevFilters };
      if (newFilters[filterType].includes(filterName)) {
        newFilters[filterType] = newFilters[filterType].filter(
          (item:any) => item !== filterName
        );
        if (filterName === "PD_VWAP") {
          setPdVwapDays((prev:any) => {
            const newPdVwapDays : any = { ...prev };
            delete newPdVwapDays[filterType];
            return newPdVwapDays;
          });
        }
      } else {
        newFilters[filterType].push(filterName);
      }
      return newFilters;
    });
  };

  const handlePdVwapDayChange = (filterType:any, value:any) => {
    setPdVwapDays((prev:any) => ({
      ...prev,
      [filterType]: value,
    }));
  };


  const handleRowSelection = (rowData:any) => {
    setSelectedRows((prevSelectedRows:any) => {
      const isAlreadySelected = prevSelectedRows.some(
        (row:any) => row.symbol === rowData.symbol
      );
      if (isAlreadySelected) {
        return prevSelectedRows.filter((row:any) => row.symbol !== rowData.symbol);
      } else {
        return [...prevSelectedRows, rowData];
      }
    });
  };

  const handleSelectAllChange = (event:any) => {
    if (event.target.checked) {
      setSelectedRows(filteredData); // Select all rows
    } else {
      setSelectedRows([]); // Deselect all rows
    }
  };

  const handleAddToWatchlist = () => {
    if (selectedRows.length > 0) {
      setIsModalOpen(true);
    } else {
      alert("Please select at least one row.");
    }
  };

  // Handle input change for new watchlist name
  const handleWatchlistNameChange = (e:any) => {
    setNewWatchlistName(e.target.value);
  };

  // Handle Save Watchlist
  const handleSaveWatchlist = () => {
    if (newWatchlistName.trim()) {
      const newWatchList = {
        id: Date.now(),
        name: newWatchlistName,
        rows: selectedRows,
      };
      setWatchLists((prevWatchLists:any) => [...prevWatchLists, newWatchList]);
      setSelectedRows([]);
      setNewWatchlistName("");
      setIsModalOpen(false);
    } else {
      alert("Please enter a name for the watchlist.");
    }
  };

  // Handle Close Modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewWatchlistName("");
  };

  const handleDeleteWatchList = (watchListId:any) => {
    const updatedWatchLists = watchLists.filter((watchList:any) => watchList.id !== watchListId);
    setWatchLists(updatedWatchLists);
  };
  const handleTabChange = (event:any, newValue:any) => {
    setSelectedTab(newValue);
  };
  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      const groupKey = currentValue[key];
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    }, {});
  };
  const handleIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    // Check if the new value is a valid number and update state
    if (!isNaN(Number(newValue))) {
      setInterval(Number(newValue)); // Convert to number and set the interval
    } else {
      console.log("Invalid input"); // Optionally handle invalid input
    }
  };
    useEffect(() => {
      if (!hasFetchedRef.current) {
        const fetchData = async () => {
          try {
            const storedData: string = await getInstruments();
            const lines = storedData.trim().split('\n');
            const headers = lines[0].split(',');
            hasFetchedRef.current = true;
            const parsedData = await Promise.all(
              lines.slice(1).map(async line => {
                const values = line.split(',');
                const instrument: any = {};
                headers.forEach((header, index) => {
                  instrument[header] = values[index];
                });
                return instrument;
              })
            );
            setInstrumentList(parsedData);
          } catch (error) {
            if (axios.isCancel(error)) {
              console.log('Request canceled:', error.message);
            } else {
              console.error('Error fetching data:', error);
            }
          }
        };
        fetchData();
        return () => {
          console.log('Performing cleanup operations...');
        };
      }
    }, []);

    useEffect(() => {
      if (parsedData && parsedData.length > 0) {
        let tokens = parsedData.map(x => x.instrumentToken);
    
        const userDetails = localStorage.getItem("userDetails")
          ? JSON.parse(localStorage.getItem("userDetails") || "")
          : {};
        const { accesstoken, apikey } = userDetails;
    
        if (tokens && tokens.length > 0) {
          axios.get<Response>('https://api.insperoninvestmentsolutions.com/api/getLiveSpread', {
            params: { apikey, accesstoken, tokens },
          })
            .then((response) => {
              console.log("API Response:", response.data);
              // Handle successful response here
            })
            .catch((error) => {
              console.error('Error fetching live spread data:', error);
            });
        } else {
          console.error("No tokens available for the API request.");
        }
      }
    }, [parsedData]);

    const fetchIndexData = async () => {
      try {
        const ws = new WebSocket('ws://localhost:7789');
    
        ws.onopen = () => {
          console.log('Connected to WebSocket server');
        };
    
        ws.onmessage = (event) => {
          const dataWS: WebSocketData = JSON.parse(event.data);
          if (dataWS && dataWS.ticks && dataWS.ticks.length > 0) {
            console.log('Received ticks index:', dataWS.ticks);
            const tickMap = new Map(dataWS.ticks.map((tick: any) => [String(tick.instrument_token), tick]));
            setParsedData(prevData => {
              return prevData.map((row) => {
                const tick = tickMap.get(String(row.instrumentToken));
                if (tick) {
                  return {
                    ...row,
                    LastTradedPrice: tick.last_price, // Update the LastTradedPrice
                  };
                }
                return row;
              });
            });
          }
        };
    
        ws.onclose = () => {
          console.log('Disconnected from WebSocket server');
        };
    
        ws.onerror = (error) => {
          console.log('WebSocket error:', error);
        };
      } catch (error) {
        console.log(error);
      }
    };
    
    useEffect(() => {
      fetchIndexData();
    }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Grid
        container
        spacing={2}
        style={{ maxWidth: "100%" }}
        alignItems="center"
      >
        {/* Select Index Composition File Section */}
        <Grid item xs={12} sm={4} md={4} style={{ maxWidth: '33%' }}>
      <Button variant="contained" component="label">
        {fileName ? fileName : 'Import Index Composition File'} {/* Conditionally render text */}
        <input
          type="file"
          accept=".csv, .xlsx, .xls"
          onChange={handleImportFile}
          hidden
        />
      </Button>
    </Grid>

        {/* Metrics Section */}
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          container
          spacing={1}
          style={{ maxWidth: "33%" }}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              Points Moved : {pointsMoved}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              SpotLTPDiff : {spotLTPDiff}
            </Typography>
          </Grid>
          <Grid item xs={4}>
          <TextField
        label="Interval"
        value={interval}
        onChange={handleIntervalChange} 
        fullWidth
      />
          </Grid>
        </Grid>

        {/* Actions Section */}
        <Grid item xs={12} sm={4} md={4} style={{ maxWidth: "33%" }}>
          <Button
            variant="contained"
            color={timerStarted ? "error" : "success"}
            onClick={handleStartStopTimer}
            style={{ padding: "15px 20px" }}
          >
            {timerStarted ? "Stop Timer" : "Start Timer"}
          </Button>
          <Button
            variant="contained"
            style={{ padding: "15px 20px", marginLeft: "10px" }}
            color="secondary"
          >
            On
          </Button>
         
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={3} direction="column">
        <Grid container spacing={2} direction="row">
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                padding: 2,
                border: "1px solid #ddd",
                borderRadius: 1,
                width: "98%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  background: "lightgray",
                  padding: "10px 0px 10px 0px",
                }}
              >
                Up Side Filters
              </Typography>
              {/* Filter checkboxes */}
              <Grid container spacing={1} direction="column" wrap="wrap">
                {[
                  "Supertrend",
                  "VWAP",
                  "PD_VWAP",
                  "Peak",
                  "Trough",
                  "SMA_CROSS",
                  "RSI",
                ].map((filter) => {
                  const isPdVwap = filter === "PD_VWAP";
                  const label =
                    isPdVwap && pdVwapDays.upSideFilters
                      ? `PD_VWAP (${pdVwapDays.upSideFilters} day)`
                      : filter;

                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={filter}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() =>
                                  handleCheckboxChange("upSideFilters", filter)
                                }
                              />
                            }
                            label={label}
                          />
                        </Grid>

                        {filter === "PD_VWAP" &&
                          !hideDropdowns &&
                          filters.upSideFilters.includes("PD_VWAP") && (
                            <Grid item>
                              <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel>PD_VWAP Days</InputLabel>
                                <Select
                                  value={pdVwapDays.upSideFilters || ""}
                                  onChange={(e) =>
                                    handlePdVwapDayChange(
                                      "upSideFilters",
                                      Number(e.target.value)
                                    )
                                  }
                                  label="PD_VWAP Days"
                                >
                                  {[1, 2, 3, 4, 5].map((day) => (
                                    <MenuItem key={day} value={day}>
                                      {`${day} day${day > 1 ? "s" : ""}`}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                padding: 2,
                border: "1px solid #ddd",
                borderRadius: 1,
                width: "98%",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  background: "lightgray",
                  padding: "10px 0px 10px 0px",
                }}
              >
                Down Side Filters
              </Typography>
              <Grid container spacing={1} direction="column" wrap="wrap">
                {[
                  "Supertrend",
                  "VWAP",
                  "PD_VWAP",
                  "Peak",
                  "Trough",
                  "SMA_CROSS",
                  "RSI",
                ].map((filter) => {
                  const label =
                    filter === "PD_VWAP" && pdVwapDays.downSideFilters
                      ? `PD_VWAP (${pdVwapDays.downSideFilters} day)`
                      : filter;
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={filter}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={() =>
                                  handleCheckboxChange(
                                    "downSideFilters",
                                    filter
                                  )
                                }
                              />
                            }
                            label={label}
                          />
                        </Grid>
                        {/* Dropdown for PD_VWAP Days */}
                        {filter === "PD_VWAP" &&
                          !hideDropdowns &&
                          filters.downSideFilters.includes("PD_VWAP") && (
                            <Grid item>
                              <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel>PD_VWAP Days</InputLabel>
                                <Select
                                  value={pdVwapDays.downSideFilters || ""}
                                  onChange={(e) =>
                                    handlePdVwapDayChange(
                                      "downSideFilters",
                                      e.target.value
                                    )
                                  }
                                  label="PD_VWAP Days"
                                >
                                  {[1, 2, 3, 4, 5].map((day) => (
                                    <MenuItem key={day} value={day}>
                                      {day} day{day > 1 ? "s" : ""}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            margin: "0px 18px 0px 0px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilter}
            sx={{ mt: 2, padding: "15px 20px" }}
          >
            Apply Filters
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddToWatchlist}
            sx={{ mt: 2, padding: "15px 20px" }}
          >
            Add to Watchlist
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Box sx={{ overflowX: "auto", width: "99%" }}>
            <TableContainer
              component={Paper}
              sx={{
                border: "1px solid lightgray", // Add a solid black border
                borderRadius: "4px", // Optional: Adds rounded corners to the border
                padding: "8px", // Optional: Add some padding inside the container
              }}
            >
              <Table sx={{ minWidth: 500 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        onChange={handleSelectAllChange}
                        checked={selectedRows.length === parsedData.length}
                      />
                    </TableCell>
                    <TableCell>InstrumentToken</TableCell>
                    <TableCell>Symbol</TableCell>
                    <TableCell>LTP</TableCell>
                    <TableCell>~chg</TableCell>
                    <TableCell>%chg</TableCell>
                    <TableCell>High</TableCell>
                    <TableCell>Low</TableCell>
                    <TableCell>ATP</TableCell>
                    <TableCell>Weightage</TableCell>
                    <TableCell>NetMoved</TableCell>
                    <TableCell>RSI</TableCell>
                    <TableCell>SMA Cross</TableCell>
                    <TableCell>CPC</TableCell>
                    <TableCell>PPC</TableCell>
                    <TableCell>LTP ATP</TableCell>
                    <TableCell>VWAP</TableCell>
                    <TableCell>PD VWAP</TableCell>
                    <TableCell>ST</TableCell>
                    <TableCell>ST 60</TableCell>
                    <TableCell>ST Day</TableCell>
                    <TableCell>Peak</TableCell>
                    <TableCell>Trough</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsedData.map((row) => (
                    <TableRow key={row.Symbol}>
                      <TableCell>
                        <Checkbox
                          checked={selectedRows.some(
                            (selected: any) => selected.Symbol === row.Symbol
                          )}
                          onChange={() => handleRowSelection(row)}
                        />
                      </TableCell>
                      <TableCell>{row.instrumentToken}</TableCell>
                      <TableCell>{row.Symbol}</TableCell>
                      <TableCell>{row.LastTradedPrice}</TableCell>
                      <TableCell>{row.NetChangeInRs}</TableCell>
                      <TableCell>{row.PerCentChange}</TableCell>
                      <TableCell>{row.High}</TableCell>
                      <TableCell>{row.Low}</TableCell>
                      <TableCell>{row.atp}</TableCell>
                      <TableCell>{row.WEIGHTAGE}</TableCell>
                      <TableCell>{row["Points Moved"]}</TableCell>
                      <TableCell>{row.rsi}</TableCell>
                      <TableCell>{row.smaCross}</TableCell>
                      <TableCell>{row.CPC}</TableCell>
                      <TableCell>{row.PPC}</TableCell>
                      <TableCell>{row["LTP-ATP DIFF"]}</TableCell>
                      <TableCell>{row.VWAP}</TableCell>
                      <TableCell>{row.PDVWAP}</TableCell>
                      <TableCell>{row.st}</TableCell>
                      <TableCell>{row.st_60}</TableCell>
                      <TableCell>{row.st_day}</TableCell>
                      <TableCell>{row.Peak}</TableCell>
                      <TableCell>{row.Trough}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              width: 400,
              p: 3,
              backgroundColor: "white",
              margin: "auto",
              mt: 10,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" id="modal-title">
              Enter Watchlist Name
            </Typography>
            <TextField
              label="Watchlist Name"
              fullWidth
              value={newWatchlistName}
              onChange={handleWatchlistNameChange}
              sx={{ mt: 2 }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button onClick={handleCloseModal}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveWatchlist}
              >
                Save Watchlist
              </Button>
            </Box>
          </Box>
        </Modal>
      </Grid>

      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Watchlists Tabs"
        >
          {watchLists.map((watchList: any) => (
            <Tab
              key={watchList.id}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="subtitle1">{watchList.name}</Typography>
                  <IconButton
                    onClick={() => handleDeleteWatchList(watchList.id)}
                    edge="end"
                    sx={{ ml: 1 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              }
            />
          ))}
        </Tabs>

        <Box sx={{ p: 3 }}>
          {watchLists[selectedTab] && (
            <Paper sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                {Object.keys(watchLists[selectedTab].rows[0]).map((header) => (
                  <Grid item xs={2} key={header} style={{ maxWidth: "4.5%" }}>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      align="center"
                    >
                      {header}
                    </Typography>
                  </Grid>
                ))}
              </Grid>

              {watchLists[selectedTab].rows.map(
                (row: Record<string, any>, index: number) => (
                  <Grid container spacing={2} key={index}>
                    {Object.values(row).map((value: any, idx: number) => (
                      <Grid item xs={2} key={idx} style={{ maxWidth: "4.5%" }}>
                        <Typography align="center">{value}</Typography>
                      </Grid>
                    ))}
                  </Grid>
                )
              )}
            </Paper>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Watchlist;
