import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Box, Tabs, Tab, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios, { spread } from "axios";
import ModalPopup from "./ModalPopup";
import GetAppIcon from '@mui/icons-material/GetApp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Item } from "semantic-ui-react";
import Papa from "papaparse";
import { saveInstruments } from "../indexDB";
import { SelectAllOutlined } from "@mui/icons-material";
import { AnyMxRecord } from "dns";
import { markAsUntransferable } from "worker_threads";
import { Z_UNKNOWN } from "zlib";
import { EMA } from "technicalindicators";


interface LegData {
  id: number;
  name: string;
  exchange: string;
  mktsegment: string;
  contracttype: string;
  tokennumber: string;
  code: string;
  symbol: string;
  description: string;
  buysellorder: string;
  lots: string;
  optiontype: string;
  strikeprice: string;
  Close: string;
  BuyOrderSellOrder: string;
}
type TimeIntervalResults = {
  "day": { highResult: number; lowResult: number };
  "5min": { highResult: number; lowResult: number };
  "10min": { highResult: number; lowResult: number };
  "15min": { highResult: number; lowResult: number };
  "30min": { highResult: number; lowResult: number };
  "60min": { highResult: number; lowResult: number };
};
interface Column {
  id:
  | "Name"
  | "Day_High"
  | "Day_Low"
  | "High_5"
  | "Low_5"
  | "High_10"
  | "Low_10"
  | "High_15"
  | "Low_15"
  | "High_30"
  | "Low_30"
  | "High_60"
  | "Low_60"
  | "Spread"
  | "VWAP"
  | "Ema20"
  | "Ema50"
  | "Ema100";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

interface DataRow {
  [key: number]: string | number | undefined;
}
interface MarketData {
  Name: string;
  [key: string]: any;
}
interface WebSocketData {
  ticks: Array<{
    instrument_token: number;
    name: string;
    last_price: number;
    volume_traded: number,
    ohlc: {
      close: any;
      open: number;
      high: any,
      low: any,
    };
  }>;
}
const columns: Column[] = [
  { id: "Name", label: "Name", minWidth: 100 },
  { id: "Day_Low", label: "Low", minWidth: 100 },
  { id: "Day_High", label: "High", minWidth: 100 },
  { id: "Low_60", label: "Low", minWidth: 100 },
  { id: "High_60", label: "High", minWidth: 100 },
  { id: "Low_30", label: "Low", minWidth: 100 },
  { id: "High_30", label: "High", minWidth: 100 },
  { id: "Low_15", label: "Low", minWidth: 100 },
  { id: "High_15", label: "High", minWidth: 100 },
  { id: "Low_10", label: "Low", minWidth: 100 },
  { id: "High_10", label: "High", minWidth: 100 },
  { id: "Low_5", label: "Low", minWidth: 100 },
  { id: "High_5", label: "High", minWidth: 100 },
  { id: "Spread", label: "Spread", minWidth: 100 },
  { id: "VWAP", label: "VWAP", minWidth: 100 },
  { id: "Ema20", label: "20 EMA", minWidth: 100 },
  { id: "Ema50", label: "50 EMA", minWidth: 100 },
  { id: "Ema100", label: "100 EMA", minWidth: 100 },
];

interface FinalData {
  Index:number;
  Name: string;
  Ema20: string;
  Ema50: string;
  Ema100: string;
  VWAP: string | null;
  Spread: string | null;
  Day_Low: string;
  Day_High: string;
  Low_10: string;
  High_10: string;
  Low_5: string;
  High_5: string;
  Low_15: string;
  High_15: string;
  High_30: string;
  Low_30: string;
  High_60: string;
  Low_60: string;
}
interface CandleData {
  timestamp: string; // ISO date string or a timestamp in your format
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
}
const SpreadView = () => {
  const [data, setData] = useState<any[]>([]);
  const [dataColumns, setDataColumns] = useState<string[]>([]);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [open, setOpen] = React.useState(false);
  const [mainText, setMainText] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const filteredData: DataRow[] = data.length > 0 ? data.filter((row: DataRow) =>
    dataColumns.every((column: string, colIndex: number) => {
      const filterValue: string | undefined = filters[column];
      if (!filterValue) return true;
      const cellValue: string = row[colIndex]?.toString().toLowerCase() || "";
      return cellValue.includes(filterValue.toLowerCase());
    })) : [];

  const [MarketData, setMarketData] = useState<any[]>([]);
  const [finalData, setFinalData] = useState<FinalData[]>([]);
  const [removedRecords, setRemovedRecords] = useState<Set<number>>(new Set());
  const [mainSpreadName, setMainSpreadName] = useState<string>();

  const [socketDATA, setSocketDATA] = useState<any>([]);
  const [legsId, setLegsId] = useState<any[]>([]);
  let ws: WebSocket | null = null;
  let MarketDataList : any[] = [];
  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      setData(filteredData);
    }
  }, [filteredData]);



  const togglePopup = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  function getTodayAtNineAM() {
    const date = new Date();
    date.setUTCHours(9, 0, 0, 0);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}+${hours}:${minutes}:${seconds}`;

  }

  const calculateCustomEMA = (data:any[], period:any) => {
    const ema = [];
  
    // Step 1: Calculate the Simple Moving Average (SMA) for the first `period` entries
    const sma = data.slice(0, period).reduce((acc, curr) => acc + curr, 0) / period;
    
    // Step 2: Start the EMA array with SMA
    ema.push(sma);
    
    // Step 3: Calculate the EMA for the remaining values
    const multiplier = 2 / (period + 1);
    for (let i = period; i < data.length; i++) {
      const currentEMA : any = (data[i] - ema[i - 1]) * multiplier + ema[i - 1];
      ema.push(currentEMA);
    }
    
    return ema;
  };
  
  const calculateBuySellResults = (records: any[], type: "buy" | "sell") => {

    return records.map((record: any) => {
          const totalLots = parseInt(record.Lots, 10);
          const dayHighResult = record.Day_High * totalLots;
          const dayLowResult = record.Day_Low * totalLots;
          const Day_Close = record.Day_Close;
          const Day_High = record.Day_High;
          const Day_Low = record.Day_Low;
          const Day_Volume = record.Day_Volume;

          const timeResults = {
            "5min": {
              highResult: record.High_5 * totalLots,
              lowResult: record.Low_5 * totalLots,
            },
            "10min": {
              highResult: record.High_10 * totalLots,
              lowResult: record.Low_10 * totalLots,
            },
            "15min": {
              highResult: record.High_15 * totalLots,
              lowResult: record.Low_15 * totalLots,
            },
            "30min": {
              highResult: record.High_30 * totalLots,
              lowResult: record.Low_30 * totalLots,
            },
            "60min": {
              highResult: record.High_60 * totalLots,
              lowResult: record.Low_60 * totalLots,
            },
            "day": {
              highResult: record.dayHigh * totalLots,
              lowResult: record.dayLow * totalLots,
            },
          };

          return {
            dayResult: dayHighResult,
            dayLowResult: dayLowResult,
            timeResults: timeResults,
            Day_Close : Day_Close,
            Day_High : Day_High,
            Day_Low : Day_Low,
            Day_Volume : Day_Volume
          };
    });
  };
  const calculateSpread = (Currentprice: any, Lots: any) => {
    // Filter out null or undefined values from both arrays
    const filteredCurrentPrice = Currentprice.filter((value: any) => value != null && value != 0);
    const filteredLots = Lots.filter((value: any) => value != null && value != 0);

    // Ensure both arrays have the same length after filtering
    const minLength = Math.min(filteredCurrentPrice.length, filteredLots.length);
    if(Currentprice.length != minLength){
      return 0;
    }
    // Multiply corresponding elements and accumulate the result
    let result = 0;
    for (let i = 0; i < minLength; i++) {
        result += filteredCurrentPrice[i] * filteredLots[i];
    }

    return result;
};


  function getLastFriday(currentTime:any) {
    const year = currentTime.getFullYear();
    const month = String(currentTime.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(currentTime.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const getCurrentTimeFormatted = (now :any,update:boolean) => {
   
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(now.getDate()).padStart(2, "0");
  
    const hour = String(now.getHours()).padStart(2, "0");
    const min = String(now.getMinutes()).padStart(2, "0");
    const sec = String(now.getSeconds()).padStart(2, "0");
  
    return  update ? `${year}-${month}-${day} ${hour}:${min}:${sec}` : `${year}-${month}-${day}+${hour}:${min}:${sec}`;
  };

  const fetchHistoricalData = async (legdata: LegData, id: number, mainText: string) => {
    try {
      setOpen(false);
      const startTime = getTodayAtNineAM();
      const userDetails = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails") || "") : {};
      const { accesstoken, apikey } = userDetails;
      const tokenNumber = legdata.tokennumber;

      const response = await axios.get("https://api.insperoninvestmentsolutions.com/api/getHistoricalData", { params: { tokenNumber, apikey, accesstoken, day: startTime } });
      
      const currentTime = new Date();
      const targetTime = new Date(); targetTime.setHours(15, 20, 0, 0); // 3:20 PM
      const previousFriday = getLastFriday(currentTime);
  
      const to = currentTime > targetTime ? previousFriday + " 15:30:00" : getCurrentTimeFormatted(new Date(),true);
      // Subtract 10 days
      const from20 = getCurrentTimeFormatted(new Date(new Date(to).setDate(new Date(to).getDate() - 15)),false);
  
      const response_EMA20 = await axios.get("https://api.insperoninvestmentsolutions.com/api/getHistoricalData_EMA",{ params: { tokenNumber, apikey, accesstoken, from: from20 } });
   
      const candleData100 = [...response_EMA20.data.data.candles].reverse().slice(0, 250);
   
      const response20EMA = await axios.post("https://python.insperoninvestmentsolutions.com/api/ema?period=" + 20,candleData100 );
      const Ema20 = response20EMA.data.reverse()[0].ema;
      const response50EMA = await axios.post("https://python.insperoninvestmentsolutions.com/api/ema?period=" + 50,candleData100 );
      const Ema50 = response50EMA.data.reverse()[0].ema;
      const response100EMA = await axios.post("https://python.insperoninvestmentsolutions.com/api/ema?period=" + 100,candleData100);
      const Ema100 = response100EMA.data.reverse()[0].ema;
    

      const emptyTab = {
        Name: "", InstrumentToken: "", BuySellOrder: "", Close: 0, Price: 0, symbol: legdata.symbol,
        optionType: legdata.optiontype, Lots: "", buysellorder: legdata.buysellorder, legsId: id,
        Day_Low: 0, Day_High: 0, Day_Volume: 0, Day_Close: 0,
        Low_60: 0, High_60: 0, Volume_60: 0, Close_60: 0,
        Low_30: 0, High_30: 0, Volume_30: 0, Close_30: 0,
        Low_15: 0, High_15: 0, Volume_15: 0, Close_15: 0,
        Low_10: 0, High_10: 0, Volume_10: 0, Close_10: 0,
        Low_5: 0, High_5: 0, Volume_5: 0, Close_5: 0,
        Spread: 0, VWAP: 0, Ema20: 0, Ema50: 0, Ema100: 0,
      };

       emptyTab.Ema20 = Ema20;
       emptyTab.Ema50 = Ema50;
       emptyTab.Ema100 = Ema100;

      emptyTab.Name = mainText;
      emptyTab.InstrumentToken = response.data.day.instrumentToken;

      emptyTab.BuySellOrder = legdata.buysellorder;
      emptyTab.Lots = legdata.lots;

      emptyTab.Day_High = response.data.day.data.candles.length > 0 ? response.data.day.data.candles[0][2] : 0;
      emptyTab.Day_Low = response.data.day.data.candles.length > 0 ? response.data.day.data.candles[0][3] : 0;
      emptyTab.Day_Close = response.data.day.data.candles.length > 0 ? response.data.day.data.candles[0][4] : 0;
      emptyTab.Day_Volume = response.data.day.data.candles.length > 0 ? response.data.day.data.candles[0][5] : 0;

      emptyTab.High_60 = response.data["60minute"].data.candles.length > 0 ? response.data["60minute"].data.candles[0][2] : 0;
      emptyTab.Low_60 = response.data["60minute"].data.candles.length > 0 ? response.data["60minute"].data.candles[0][3] : 0;
      emptyTab.Close_60 = response.data["60minute"].data.candles.length > 0 ? response.data["60minute"].data.candles[0][4] : 0;
      emptyTab.Volume_60 = response.data["60minute"].data.candles.length > 0 ? response.data["60minute"].data.candles[0][5] : 0;

      emptyTab.High_30 = response.data["30minute"].data.candles.length > 0 ? response.data["30minute"].data.candles[0][2] : 0;
      emptyTab.Low_30 = response.data["30minute"].data.candles.length > 0 ? response.data["30minute"].data.candles[0][3] : 0;
      emptyTab.Close_30 = response.data["30minute"].data.candles.length > 0 ? response.data["30minute"].data.candles[0][4] : 0;
      emptyTab.Volume_30 = response.data["30minute"].data.candles.length > 0 ? response.data["30minute"].data.candles[0][5] : 0;

      emptyTab.High_15 = response.data["15minute"].data.candles.length > 0 ? response.data["15minute"].data.candles[0][2] : 0;
      emptyTab.Low_15 = response.data["15minute"].data.candles.length > 0 ? response.data["15minute"].data.candles[0][3] : 0;
      emptyTab.Close_15 = response.data["15minute"].data.candles.length > 0 ? response.data["15minute"].data.candles[0][4] : 0;
      emptyTab.Volume_15 = response.data["15minute"].data.candles.length > 0 ? response.data["15minute"].data.candles[0][5] : 0;

      emptyTab.High_10 = response.data["10minute"].data.candles.length > 0 ? response.data["10minute"].data.candles[0][2] : 0;
      emptyTab.Low_10 = response.data["10minute"].data.candles.length > 0 ? response.data["10minute"].data.candles[0][3] : 0;
      emptyTab.Close_10 = response.data["10minute"].data.candles.length > 0 ? response.data["10minute"].data.candles[0][4] : 0;
      emptyTab.Volume_10 = response.data["10minute"].data.candles.length > 0 ? response.data["10minute"].data.candles[0][5] : 0;

      emptyTab.High_5 = response.data["5minute"].data.candles.length > 0 ? response.data["5minute"].data.candles[0][2] : 0;
      emptyTab.Low_5 = response.data["5minute"].data.candles.length > 0 ? response.data["5minute"].data.candles[0][3] : 0;
      emptyTab.Close_5 = response.data["5minute"].data.candles.length > 0 ? response.data["5minute"].data.candles[0][4] : 0;
      emptyTab.Volume_5 = response.data["5minute"].data.candles.length > 0 ? response.data["5minute"].data.candles[0][5] : 0;

      return emptyTab;
    } catch (error) { }
  };
  async function fetchAndMergeData(legData: LegData[], RowName: string) {
    const promises = legData.map((tt: LegData) => fetchHistoricalData(tt, tt.id, RowName,));
    const results = await Promise.all(promises);
    const mergedData = results.flat();
    return mergedData;
  }
  const setFormData = async (legData: LegData[], legName: string) => {
    try {
      setMainSpreadName(legName)

      const mergedData = await fetchAndMergeData(legData, legName);
      if (mergedData !== undefined && mergedData[0] !== undefined) {
        setMarketData((prevData) => {
          // Create a map to group items by key
          const dataMap = new Map();
        
          // Populate the map with previous data
          prevData.forEach(item => {
            const key = `${item.Name}_${item.BuySellOrder}`;
            if (!dataMap.has(key)) {
              dataMap.set(key, []);
            }
            dataMap.get(key).push(item);
          });
        
          // Merge the new data into the map
          mergedData.forEach((newItem:any) => {
            const key = `${newItem.Name}_${newItem.BuySellOrder}`;
            if (!dataMap.has(key)) {
              dataMap.set(key, []);
            }
            dataMap.get(key).push(newItem);
          });
        
          // Convert the map back to an array for state update
          return Array.from(dataMap.values()).flat();
        });
        
        
      }

      const newLegsIds = mergedData.map(item => item?.legsId).filter((id, index, self) => self.indexOf(id) === index);
      setLegsId(prevLegsId => [...prevLegsId, ...newLegsIds.filter(id => !prevLegsId.includes(id))]);

    } catch (error) {
    }
  };
  useEffect(() => {
    if (MarketData && MarketData.length > 0) {
      let tokens = MarketData.map(x => x.InstrumentToken)
      const userDetails = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails") || "") : {};
      const { accesstoken, apikey } = userDetails;
      MarketDataList = MarketData;
      axios.get<Response>('https://api.insperoninvestmentsolutions.com/api/getLiveSpread', {
        params: { apikey, accesstoken, tokens },
      });
    }
  }, [MarketData]);
  const calculateVWAP = (High: any, Low: any, Close: any, Volume: any) => {
    let cumulativeVolume = 0;
    let cumulativePriceVolume = 0;
    const typicalPrice = (High + Low + Close) / 3;
    cumulativePriceVolume += typicalPrice * Volume;
    cumulativeVolume += Volume;

    const vwap = cumulativePriceVolume / cumulativeVolume;

    return vwap;
  };
  const deleteLiveDataIndex = (Name: string) => {
    setFinalData((prevData: any) => {
      const updatedData = prevData.filter((row: any) => row.Name !== Name);
      return updatedData;
    });

   // setRemovedRecords((prevRemovedRecords) => new Set(prevRemovedRecords).add(Name));
  };
  const groupBy = (array: any, key: any) => {
    return array.reduce((result: any, currentValue: any) => {
      const groupKey = currentValue[key];
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    }, {});
  };

  let ema20: string | null = null;
  let ema50: string | null = null;
  let ema100: string | null = null;
  let VWAP: string | null = null;
  let Spread: string | null;

  let buyResults: any;
  let sellResults: any;
  let sellresult = 0;
  let buyresult = 0;

const mergeAndSumResults = (buyResults:any, sellResults:any) => {
    // Assuming buyResults[] and sellResults[] are already defined
const combinedResults = [...buyResults, ...sellResults]; // Combine buy and sell results

const result = combinedResults.reduce((acc, curr) => {
    // If it's a "Buy" order, add the values
    if (curr.BuySellOrder === "Buy") {
        acc.Day_Close += curr.Day_Close || 0;
        acc.Day_High += curr.Day_High || 0;
        acc.Day_Low += curr.Day_Low || 0;
        acc.Day_Volume += curr.Day_Volume || 0;
        acc.High_60 += curr.High_60 || 0;
        acc.Low_60 += curr.Low_60 || 0;
        acc.Close_60 += curr.Close_60 || 0;
        acc.Volume_60 += curr.Volume_60 || 0;
        acc.High_30 += curr.High_30 || 0;
        acc.Low_30 += curr.Low_30 || 0;
        acc.Close_30 += curr.Close_30 || 0;
        acc.Volume_30 += curr.Volume_30 || 0;
        acc.High_15 += curr.High_15 || 0;
        acc.Low_15 += curr.Low_15 || 0;
        acc.Close_15 += curr.Close_15 || 0;
        acc.Volume_15 += curr.Volume_15 || 0;
        acc.High_10 += curr.High_10 || 0;
        acc.Low_10 += curr.Low_10 || 0;
        acc.Close_10 += curr.Close_10 || 0;
        acc.Volume_10 += curr.Volume_10 || 0;
        acc.High_5 += curr.High_5 || 0;
        acc.Low_5 += curr.Low_5 || 0;
        acc.Close_5 += curr.Close_5 || 0;
        acc.Volume_5 += curr.Volume_5 || 0;
        acc.Ema20 += curr.Ema20 || 0;
        acc.Ema50 += curr.Ema50 || 0;
        acc.Ema100 += curr.Ema100 || 0;
    }
    // If it's a "Sell" order, subtract the values
    else if (curr.BuySellOrder === "Sell") {
        acc.Day_Close -= curr.Day_Close || 0;
        acc.Day_High -= curr.Day_High || 0;
        acc.Day_Low -= curr.Day_Low || 0;
        acc.Day_Volume -= curr.Day_Volume || 0;
        acc.High_60 -= curr.High_60 || 0;
        acc.Low_60 -= curr.Low_60 || 0;
        acc.Close_60 -= curr.Close_60 || 0;
        acc.Volume_60 -= curr.Volume_60 || 0;
        acc.High_30 -= curr.High_30 || 0;
        acc.Low_30 -= curr.Low_30 || 0;
        acc.Close_30 -= curr.Close_30 || 0;
        acc.Volume_30 -= curr.Volume_30 || 0;
        acc.High_15 -= curr.High_15 || 0;
        acc.Low_15 -= curr.Low_15 || 0;
        acc.Close_15 -= curr.Close_15 || 0;
        acc.Volume_15 -= curr.Volume_15 || 0;
        acc.High_10 -= curr.High_10 || 0;
        acc.Low_10 -= curr.Low_10 || 0;
        acc.Close_10 -= curr.Close_10 || 0;
        acc.Volume_10 -= curr.Volume_10 || 0;
        acc.High_5 -= curr.High_5 || 0;
        acc.Low_5 -= curr.Low_5 || 0;
        acc.Close_5 -= curr.Close_5 || 0;
        acc.Volume_5 -= curr.Volume_5 || 0;
        acc.Ema20 -= curr.Ema20 || 0;
        acc.Ema50 -= curr.Ema50 || 0;
        acc.Ema100 -= curr.Ema100 || 0;
    }
    for (let key in acc) {
      if (acc.hasOwnProperty(key)) {
        acc[key] = Math.abs(acc[key]);
    }
    }
    return acc;
}, {
    Day_Close: 0,
    Day_High: 0,
    Day_Low: 0,
    Day_Volume: 0,
    High_60: 0,
    Low_60: 0,
    Close_60: 0,
    Volume_60: 0,
    High_30: 0,
    Low_30: 0,
    Close_30: 0,
    Volume_30: 0,
    High_15: 0,
    Low_15: 0,
    Close_15: 0,
    Volume_15: 0,
    High_10: 0,
    Low_10: 0,
    Close_10: 0,
    Volume_10: 0,
    High_5: 0,
    Low_5: 0,
    Close_5: 0,
    Volume_5: 0,
    Ema20: 0,
    Ema50: 0,
    Ema100: 0
});
return result;
};

         
  const fetchIndexData = async () => {
    try {

      ws = new WebSocket('ws://localhost:7789');

      ws.onopen = () => {
        console.log('Connected to WebSocket server');
      };

      ws.onmessage = (event) => {
        const dataWS: WebSocketData = JSON.parse(event.data);
        if (dataWS && dataWS.ticks && dataWS.ticks.length > 0) {
          console.log('Received ticks index:', dataWS.ticks);
          let finalResults: any[]

          if (MarketDataList && MarketDataList.length > 0) {

            const groupedData = groupBy(MarketDataList, "Name");
            const newDataList : FinalData[] = [];
            Object.entries(groupedData).map(([key, value], index) => {

              const groupData = value as any[];
              const buyRecords = groupData.filter((item: any) => item?.BuySellOrder === "Buy");
              const sellRecords = groupData.filter((item: any) => item?.BuySellOrder === "Sell");

              buyResults = calculateBuySellResults(buyRecords, "buy");
              sellResults = calculateBuySellResults(sellRecords, "sell");

              if (sellRecords.length > 0 && sellRecords.length == 1 && buyRecords.length === 0) {
                  let ltp = dataWS.ticks.find((tick: any) => (+tick.instrument_token) === (+sellRecords[0].InstrumentToken))?.last_price;
                  Spread = ltp ? ltp.toFixed(2) : "0";
              }

              else if (buyRecords.length > 0 && buyRecords.length == 1 && sellRecords.length === 0) {
                let ltp = buyRecords.map((record: any) => {
                  const matchingTick = dataWS.ticks.find((tick: any) => String(tick.instrument_token) === String(record.InstrumentToken));
                  return matchingTick ? matchingTick.last_price : 0;
                });
                Spread =  ltp[0].toFixed(2);
              }

               else {
                  // Create a map of instrument tokens to ticks for faster lookup
                  const tickMap = new Map(dataWS.ticks.map((tick: any) => [String(tick.instrument_token), tick]));

                  // Function to map records to their respective token, price, and lot size
                  const mapRecords = (records: any[]) => {
                    return records.map((record: any) => {
                      const tick = tickMap.get(String(record.InstrumentToken));
                      return tick ? {
                        token: tick.instrument_token,
                        price: tick.last_price,
                        lots: Number(record.Lots)
                      } : { token: null, price: null, lots: 0 };
                    });
                  };
                  // Map sell and buy records
                  const sellData = mapRecords(sellRecords);
                  const buyData = mapRecords(buyRecords);

                  // Separate tokens, prices, and lots from the mapped data
                  const selltoken = sellData.map((data: any) => data.token);
                  const sellprice = sellData.map((data: any) => data.price);
                  const selllots = sellData.map((data: any) => data.lots);

                  const buytoken = buyData.map((data: any) => data.token);
                  const buyprice = buyData.map((data: any) => data.price);
                  const buylots = buyData.map((data: any) => data.lots);

                 // Calculate sellresult if the conditions are met
                  sellresult = sellprice.length > 0 ? calculateSpread(sellprice, selllots) : sellresult;

                  // Calculate buyresult if the conditions are met
                  buyresult = buyprice.length > 0 ? calculateSpread(buyprice, buylots) : buyresult;

                  // Update Spread only if both sellresult and buyresult are non-zero
                  Spread = buyresult != 0 && sellresult != 0 ? (Math.abs(buyresult - sellresult)).toFixed(2) : "0";
             }
            
              // Merge the objects based on 'Name' and aggregate the values
                const mergedRow = mergeAndSumResults(buyRecords, sellRecords);

                const vwap = calculateVWAP(mergedRow.Day_High,mergedRow.Day_Low,mergedRow.Day_Close,mergedRow.Day_Volume);
                  VWAP = !Number.isNaN(vwap) ?  vwap.toFixed(2) : "";
              
                  const newData: FinalData = {
                    Index : index,
                    Name: key ?? "",
                    Ema20: mergedRow.Ema20.toFixed(2) ?? "",
                    Ema50: mergedRow.Ema50.toFixed(2) ?? "",
                    Ema100: mergedRow.Ema100.toFixed(2) ?? "",
                    VWAP: VWAP ?? "",
                    
                    Spread: Spread,
  
                    Day_Low: mergedRow.Day_Low.toFixed(2),
                    Day_High:  mergedRow.Day_High.toFixed(2),
  
                    Low_10:  mergedRow.Low_10.toFixed(2),
                    High_10:  mergedRow.High_10.toFixed(2),
  
                    Low_5:  mergedRow.Low_5.toFixed(2),
                    High_5:  mergedRow.High_5.toFixed(2),
  
                    Low_15:  mergedRow.Low_15.toFixed(2),
                    High_15:  mergedRow.High_15.toFixed(2),
  
                    High_30:  mergedRow.High_30.toFixed(2),
                    Low_30:  mergedRow.Low_30.toFixed(2),
  
                    High_60:  mergedRow.High_60.toFixed(2),
                    Low_60:  mergedRow.Low_60.toFixed(2),
                  };
                  newDataList.push(newData);
              });

              setFinalData((prevData: FinalData[]) => {
                const updatedData = [...prevData]; // Clone prevData to avoid mutating it
              
                newDataList.forEach((e) => {
                  const existingIndex = updatedData.findIndex((item) => item.Name === e.Name);
                  if (existingIndex !== -1) {
                      e.Spread = e.Spread !="0"  && e.Spread != "0.00" ? updatedData[existingIndex].Name == e.Name ? e.Spread : "0" : updatedData[existingIndex].Spread;
                    // If it exists, update the existing entry
                    updatedData[existingIndex] = e;
                  } else {
                    // If it doesn't exist, add the new data
                    updatedData.push(e);
                  }
                });
              
                return updatedData;
              });
          }
        }
      }
      ws.onclose = () => {
        console.log('Disconnected from WebSocket server');
      };

      ws.onerror = (error) => {
        console.log('WebSocket error:', error);
      };
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchIndexData();
  });
  
  const exportToCSV = () => {
    const formattedData = finalData.map((row) => {
      const newRow = { ...row };
      newRow.Ema20 = row.Ema20
      newRow.Ema50 = row.Ema50 
      newRow.Ema100 = row.Ema100
      return newRow;
    });
  
    const csv = Papa.unparse(formattedData);
   
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "final_data.csv";
    link.click();
  };
  

  const handleImportCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          const parsedData = result.data.map((row: any) => {
            return {
              ...row,
              Ema20: row.Ema20 ? row.Ema20.split(", ").map(Number) : null,
              Ema50: row.Ema50 ? row.Ema50.split(", ").map(Number) : null,
              Ema100: row.Ema100 ? row.Ema100.split(", ").map(Number) : null, 
            };
          });
          setFinalData(parsedData); 
        },
        header: true, 
      });
    }
  };  

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-last", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: 2
          }}
        >
          {/* Left-aligned buttons */}
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button variant="contained" onClick={togglePopup}>
              Add Contract
            </Button>
            <Button variant="contained" component="label">
              Import CSV
              <input type="file" accept=".csv" onChange={handleImportCSV} hidden />
            </Button>
            <Button onClick={exportToCSV} variant="contained" startIcon={<GetAppIcon />}>
              Export
            </Button>
          </Box>

          {/* Right-aligned buttons  */}
          <Box sx={{ display: "flex", gap: 1 }}>
          </Box>
        </Box>
        &nbsp;
        <ModalPopup
          show={open}
          onClose={handleClose}
          setFormData={setFormData}
        />
      </Box>
      <div>
        <Paper sx={{ width: "100%" }}>
          <TableContainer
            sx={{ maxHeight: 700, minHeight: "700px", border: "1px solid black" }}
          >
            <Table stickyHeader aria-label="sticky table" sx={{ maxHeight: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid black" }}
                    align="center"
                    colSpan={2}
                  >
                    Day
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid black" }}
                    align="center"
                    colSpan={2}
                  >
                    60 Min
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid black" }}
                    align="center"
                    colSpan={2}
                  >
                    30 Min
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid black" }}
                    align="center"
                    colSpan={2}
                  >
                    15 Min
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid black" }}
                    align="center"
                    colSpan={2}
                  >
                    10 Min
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: "1px solid black" }}
                    align="center"
                    colSpan={2}
                  >
                    5 Min
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) =>
                    column.label === "Name" ? (
                      <>
                        <TableCell></TableCell>
                        <TableCell
                          sx={{
                            borderRight:
                              column.id.includes("high") || column.id === "Name"
                                ? "1px solid black"
                                : "",
                          }}
                          key={column.id}
                          align={column.align}
                          style={{ top: 57, minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      </>
                    ) : (
                      <TableCell
                        sx={{
                          borderRight:
                            column.id.includes("high") || column.id === "Name"
                              ? "1px solid black"
                              : "",
                        }}
                        key={column.id}
                        align={column.align}
                        style={{ top: 57, minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {finalData
                  .filter((row) => !removedRecords.has(row.Index))
                  .map((row: FinalData) => (
                    <TableRow hover role="checkbox" tabIndex={1} key={row.Name}>
                      <TableCell sx={{ borderRight: "1px solid black" }}>
                        <IconButton onClick={() => deleteLiveDataIndex(row.Name)}>
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};

export default SpreadView;
