import React, { useState ,useEffect,useRef, useMemo } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AppProvider, Navigation, NavigationItem, Router as ToolpadRouter } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { PageContainer } from '@toolpad/core/PageContainer';
import { useNavigate, BrowserRouter, Routes as _Routes, Route } from 'react-router-dom';
import { Box, Typography, Grid, TablePagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import CalculateIcon from '@mui/icons-material/Calculate';
import FunctionsIcon from '@mui/icons-material/Functions';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Login from './Login/login';
import MarginCalculator from './Pages/MarginCalculator';
import Quote from './Quote/Quote';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SpreadView from './Spread/SpreadView';
import Watchlist from './Watchlist/Watchlist';
import axios from 'axios';
import { saveInstruments } from './indexDB';

const NAVIGATION: NavigationItem[] = [
  {
    kind: 'header',
    title: '',
  },
  {
    segment: 'dashboard',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    segment: 'spread_view',
    title: 'Spread View',
    icon: <ListAltIcon />,
  },
  {
    segment: 'margin_calculator',
    title: 'Margin Calculator',
    icon: <CalculateIcon />,
  },
  {
    segment: 'market_quotes',
    title: 'Market Quotes',
    icon: <TrendingUpIcon />,
  },
  {
    segment: 'login',
    title: 'Login',
    icon: <LoginIcon />,
  },
];
const Dashboard = () => (
  <div>
  <Watchlist/>
  </div>
);

// Main application component
const App: React.FC = () => {
  const navigate = useNavigate();
  const handleNavigationClick = (title: string) => {
    if (title === 'Dashboard') {
      navigate('/dashboard');
    }
  };

const navigationWithHandlers = NAVIGATION.map((item: any) => ({
    ...item,
    onClick: item.segment ? () => handleNavigationClick(item.segment) : undefined,
}));

const BRANDING = {
    title: 'Algo Traders',
};
useEffect(() => {

  const fetchInstruments = async () => {
    try {

      const response = await axios.get('https://api.insperoninvestmentsolutions.com/api/instruments');
      saveInstruments(response.data)
    } catch (err) {
    }
  };

  fetchInstruments();
}, []); // 
return (
    <AppProvider navigation={navigationWithHandlers} branding={BRANDING}>
      <DashboardLayout>
        <PageContainer>
          <Grid>
            <Grid>
              <_Routes>
                <Route path="/dashboard" element={<Dashboard/>} />
                <Route path="*" element={<Dashboard  />} />
                <Route path="/login" element={<Login />} />
                <Route path="/margin_calculator" element={<MarginCalculator />} />
                <Route path="/market_quotes" element={<Quote />} />
                <Route path="/spread_view" element={<SpreadView />} />
              </_Routes>
            </Grid>
          </Grid>
        </PageContainer>
      </DashboardLayout>
    </AppProvider>
  );
}

interface DashboardLayoutBasicProps {
  window?: () => Window;
}

const DashboardLayoutBasic: React.FC<DashboardLayoutBasicProps> = (props) => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default DashboardLayoutBasic;
