import React, { useState } from "react";
import { Button, Container, Box, Tabs, Tab } from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import Papa from "papaparse";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import * as XLSX from "xlsx";

// Define interfaces for types
interface ApiResponse {
  data: Record<string, any>[] | Record<string, any>;
  [key: string]: any;
}

interface MarginCalculatorr {
  tradingSymbol: string;
  finalSpan: any;
  finalExposure: number;
  totalMargin: number;
  finalOptionPremium: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// Styled component for the hidden input
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const MarginCalculator = () => {
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);

  // Handle file input change (CSV upload)
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          if (Array.isArray(results.data)) {
            setData(results.data);
            localStorage.setItem("csvData", JSON.stringify(results.data));
          }
        },
        error: (error) => {
          console.error("CSV Parsing Error:", error);
          alert("Error parsing CSV file. Please check the format.");
        },
      });
    }
  };

  // Handle sending data to API
  const sendDataToApi = async (data: any[]) => {
    const url = "https://api.insperoninvestmentsolutions.com/api/calculateMargin";
    const userDetails = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails") || "") : {};
    const chunkSize = 1; // Number of rows per chunk
    let responseDataArray: any[] = [];
    setLoading(true);  // Set loading state before making API calls

    for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize);
      const payload = {
        apikey :userDetails.apikey, // Include apiKey in the payload
        accesstoken :userDetails.accesstoken,
        data: chunk, // Add chunk data to the payload
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "X-Kite-Version": "3",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const jsonData = await response.json();
        responseDataArray.push(jsonData);
      } catch (error) {
        console.error("API Request Error:", error);
      }
    }

    const apiResponse: ApiResponse = {
      data: responseDataArray,
    };

    setApiResponse(apiResponse);
    setLoading(false);  // Set loading to false after API calls are complete
  };

  // Handle API call button click
  const handleApiCall = () => {
    if (data.length > 0) {
      sendDataToApi(data);
    } else {
      alert("Please upload a CSV file first.");
    }
  };

  // Handle tab change
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Create columns for Material React Table dynamically based on data
  const createColumns = (data: Record<string, any>) => {
    const keys = data ? Object.keys(data) : [];
    return keys.map((key) => ({
      accessorKey: key,
      header: key.charAt(0).toUpperCase() + key.slice(1),
      size: 150,
      Cell: ({ row }: { row: { original: Record<string, any> } }) => {
        const value = row.original[key];
        if (key === "ath_date" && value) {
          return dayjs(value).format("MM-DD-YYYY");
        }
        return value ?? "";
      },
    }));
  };

  // Extract data for the table
  const extractDataForTable = (apiResponse: ApiResponse): MarginCalculatorr[] => {
    // Check if apiResponse or apiResponse.data is missing or not an array
    if (!apiResponse || !Array.isArray(apiResponse.data) || apiResponse.data.length === 0) {
      return []; // Return an empty array if no valid data is found
    }
  
    const extractedData: MarginCalculatorr[] = [];
    const groupedData: Record<string, MarginCalculatorr & { count: number }> = {};
  
    // Iterate over apiResponse.data assuming it's an array of objects
    apiResponse.data.forEach((item: any) => {
      // Check if 'item' has a 'data' property with the expected structure
      if (item && item.data) {
        // Iterate through the keys in 'item.data'
        Object.keys(item.data).forEach((key) => {
          const response = item.data; // response could be the 'data' property of each item
          if (response && response.orders) {
            // Iterate through orders and extract necessary fields
            response.orders.forEach((order: { tradingsymbol: string }) => {
              const baseSymbol = order.tradingsymbol.split(/\d{2}[A-Z]{3}/)[0]; // Extract base symbol
  
              // Initialize entry if it doesn't already exist for this base symbol
              if (!groupedData[baseSymbol]) {
                groupedData[baseSymbol] = {
                  tradingSymbol: baseSymbol,
                  finalSpan: 0,
                  finalExposure: 0,
                  totalMargin: 0,
                  finalOptionPremium: 0,
                  count: 0,
                };
              }
  
              // Accumulate values for the grouped data
              groupedData[baseSymbol].finalSpan += response.final?.span || 0;
              groupedData[baseSymbol].finalExposure += response.final?.exposure || 0;
              groupedData[baseSymbol].totalMargin += response.final?.total || 0;
              groupedData[baseSymbol].finalOptionPremium += response.final?.option_premium || 0;
              groupedData[baseSymbol].count += 1;
            });
          }
        });
      }
    });
  
    // Convert grouped data to an array and calculate averages
    Object.values(groupedData).forEach((item) => {
      extractedData.push({
        tradingSymbol: item.tradingSymbol,
        finalSpan: parseFloat((item.finalSpan / item.count).toFixed(2)), // Average finalSpan
        finalExposure: parseFloat((item.finalExposure / item.count).toFixed(2)), // Average finalExposure
        totalMargin: parseFloat((item.totalMargin / item.count).toFixed(2)), // Average totalMargin
        finalOptionPremium: parseFloat((item.finalOptionPremium / item.count).toFixed(2)), // Average finalOptionPremium
      });
    });
  
    return extractedData;
  };
  

  // Export to CSV
  const exportCSV = () => {
    const data = extractDataForTable(apiResponse as ApiResponse);
    const headers = ['Trading Symbol', 'Final Span', 'Final Exposure', 'Total Margin', 'Final Option Premium'];
    const csvData = [
      headers,
      ...data.map(item => [
        item.tradingSymbol,
        item.finalSpan,
        item.finalExposure,
        item.totalMargin,
        item.finalOptionPremium,
      ])
    ];
    const csvContent = csvData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'api_response.csv';
    link.click();
  };

  // Export to Excel
  const exportExcel = () => {
    const data = extractDataForTable(apiResponse as ApiResponse);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'api_response.xlsx');
  };

  // Material React Table Columns
  const columns: MRT_ColumnDef<MarginCalculatorr>[] = [
    { accessorKey: "tradingSymbol", header: "Trading Symbol", size: 150 },
    { accessorKey: "finalSpan", header: "Final Span", size: 150 },
    { accessorKey: "finalExposure", header: "Final Exposure", size: 150 },
    { accessorKey: "totalMargin", header: "Total Margin", size: 100 },
    { accessorKey: "finalOptionPremium", header: "Final Option Premium", size: 200 },
  ];

  return (
    <>
      <Container>
        <Box display="flex" mt={3}>
          <Box component="aside" mr={3} display="flex" flexDirection="row" alignItems="center" gap={2}>
            <Button variant="contained" component="label" startIcon={<CloudUploadIcon />}>
              Upload files
              <VisuallyHiddenInput type="file" onChange={handleFileChange} multiple />
            </Button>
            <Button variant="contained" disabled={data.length === 0} color="primary" onClick={handleApiCall}>
              Send Data to API
            </Button>
          </Box>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: "divider", margin: "24px 0px 0px 0px" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Margin Calculator Request" />
            <Tab label="Margin Calculator Response" />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <MaterialReactTable
            columns={createColumns(data[0])}
            data={data}
            enableColumnOrdering
            enableColumnPinning
            initialState={{
              showGlobalFilter: true,
              columnPinning: {
                left: ["mrt-row-expand", "mrt-row-select"],
                right: ["mrt-row-actions"],
              },
            }}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
  {apiResponse ? (
    <MaterialReactTable
    renderTopToolbarCustomActions={
     ()=>(
      <Box mt={2}>
    <Button variant="contained" color="primary" onClick={exportCSV} disabled={!apiResponse}>
      Export CSV
    </Button>
    <Button variant="contained" color="secondary" onClick={exportExcel} disabled={!apiResponse} style={{ marginLeft: '10px' }}>
      Export Excel
    </Button>
  </Box>
     ) 
    }
      columns={columns}
      data={extractDataForTable(apiResponse as ApiResponse)}
      enableColumnOrdering
      initialState={{
        showGlobalFilter: true,
        columnPinning: {
          left: ["mrt-row-expand", "mrt-row-select"],
          right: ["mrt-row-actions"],
        },
      }}
    />
  ) : (
    <Box>No data available</Box>
  )}

  
</TabPanel>
      </Container>
    </>
  );
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default MarginCalculator;
