import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableRow } from 'semantic-ui-react';
import { getInstruments } from "../indexDB";
import Papa from 'papaparse';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem, InputLabel, FormControl, Grid, IconButton, TableContainer, TableHead, Typography, SelectChangeEvent, Autocomplete, Box } from '@mui/material';
import { Delete, AddCircle, X } from '@mui/icons-material';
import axios from "axios";
interface ModalPopupProps {
  show: boolean;
  onClose: () => void;
  setFormData: (token: any, name: any) => void;
}

interface LegData {
  id: number;
  name: string;
  exchange: string;
  mktsegment: string;
  contracttype: string;
  tokennumber: string;
  code: string;
  symbol: string;
  description: string;
  buysellorder: string;
  lots: string;
  optiontype: string;
  strikeprice: string;
  expiry: string;
  BuyOrderSellOrder: string
}

const ModalPopup: React.FC<ModalPopupProps> = ({ show, onClose, setFormData }) => {
  const [leg, setLegs] = useState<number>(1);
  const [mainText, setMainText] = useState<string>("");
  const [exchange, setexchange] = useState([
    { key: 1, text: 'NseFO', value: 'NseFO' },
    { key: 2, text: 'NseCM', value: 'NseCM' }
  ]);
  const [instrumentList, setInstrumentList] = useState<any[]>([]);
  const [mktsegment_FO, setmktsegment_FO] = useState([
    { key: 1, text: 'FUT', value: 'FUT' },
    { key: 2, text: 'OPT', value: 'OPT' }
  ]);
  const [mktsegment_CM, setmktsegment_CM] = useState([{ key: 1, text: 'EQ', value: 'EQ' }]);
  const [contracttype_F, setcontracttype_F] = useState([
    { key: 1, text: 'FUTIDX', value: 'FUTIDX' },
    { key: 2, text: 'FUTSTK', value: 'FUTSTK' }
  ]);
  const [contracttype_O, setcontracttype_O] = useState([
    { key: 1, text: 'OPTIDX', value: 'OPTIDX' },
    { key: 2, text: 'OPTSTK', value: 'OPTSTK' }
  ]);

  const [symbolIDX, setsymbolIDX] = useState<string[]>([]);
  const [symbolSTK, setsymbolSTK] = useState<string[]>([]);
  const [symbolEQ, setsymbolEQ] = useState<string[]>([]);
  const [expiryOpt, setexpiryOpt] = useState<{ [key: number]: string[] }>({});
  const [strikeprices, setstrikeprices] = useState<{ [key: number]: string[] }>({});
  const [existingNames, setExistingNames] = useState<string[]>([]);

  const call_BS = [{ key: 1, text: 'Buy', value: 'Buy' }, { key: 2, text: 'Sell', value: 'Sell' }];
  const [legDataArray, setLegDataArray] = useState<LegData[]>([
    {
      id: 0,
      name: '',
      exchange: '',
      mktsegment: '',
      contracttype: '',
      tokennumber: '',
      code: '',
      symbol: '',
      description: '',
      buysellorder: '',
      lots: '',
      optiontype: '',
      strikeprice: '',
      expiry: '',
      BuyOrderSellOrder: ''
    },
  ]);

  const [isOpen, setisOpen] = useState(show);

  useEffect(() => {
    
    const fetchData = async () => {
      const storedData = await getInstruments();
      try {
        const jsonData = Papa.parse(storedData, {
          header: true,
          skipEmptyLines: true,
        });
        setInstrumentList(jsonData.data as any[]);
      } catch (error) {
        console.error("Error parsing CSV:", error);
      }
    };

    fetchData();
  }, []);

  const symbolDropDownFilter = (Type: string) => {

    let response: any = "";
    if (instrumentList.length > 0) {
      if (Type === "EQ") {
        const startsWithNumberRegex = /^\d/;
        response = instrumentList.filter(x =>
          x.exchange === "NSE" &&
          x.segment === "NSE" &&
          x.instrument_type === "EQ" &&
          x.name &&
          !x.name.includes("%") &&
          !startsWithNumberRegex.test(x.tradingsymbol)
        );
      } else if (Type === "IDX") {
        response = instrumentList
          .filter(x =>
            x.exchange === "NFO" &&
            x.instrument_type === "FUT" &&
            x.name &&
            !x.name.includes("NIFTY")
          )
          .map(x => x.name.replace(/"/g, '').trim())
          .filter((value, index, self) => self.indexOf(value) === index);
      } else if (Type === "STK") {
        response = instrumentList
          .filter(x =>
            x.exchange === "NFO" &&
            x.instrument_type === "FUT" &&
            x.name &&
            x.name.includes("NIFTY")
          )
          .map(x => x.name.replace(/"/g, '').trim())
          .filter((value, index, self) => self.indexOf(value) === index);
      }
    }
    return response;
  };

  useEffect(() => {
    if (instrumentList.length > 0 && legDataArray.length) {
      const data = symbolDropDownFilter("EQ");
      const filteredsymbol = data.map((item: any) => item.tradingsymbol);
      setsymbolEQ(filteredsymbol);
    }
  }, [instrumentList]);

  useEffect(() => {
    if (instrumentList.length > 0 && legDataArray.length) {
      const data = symbolDropDownFilter("IDX");
      setsymbolIDX(data);
    }
  }, [instrumentList]);

  useEffect(() => {
    if (instrumentList.length > 0 && legDataArray.length) {
      const data = symbolDropDownFilter("STK");
      setsymbolSTK(data);
    }
  }, [instrumentList]);


  const setMainTextName = (e: any) => {
    setMainText(e);
  };



  const deleteRowByIndex = (index: number) => {
    const updatedLegData = legDataArray.filter((_, i) => i !== index);
    const reindexedData = updatedLegData.map((item, idx) => ({ ...item, id: idx }));

    setLegDataArray(reindexedData);
    setLegs(leg - 1);
  };

  const addNewRow = () => {
    const lastLeg = legDataArray[legDataArray.length - 1];

    const newLeg = {
      ...lastLeg,
      id: legDataArray.length,
      symbol: '',
      mktsegment: lastLeg.mktsegment,
      contracttype: lastLeg.contracttype,
      tokennumber: '',
      code: '',
      description: '',
      buysellorder: '',
      lots: '',
    };

    // Add the new row to the legDataArray
    setLegDataArray((prevLegData) => [...prevLegData, newLeg]);
  };

  const handleInputChange = (id: number, field: string, value: string) => {
    const updatedRows = legDataArray.map(row => row.id === id ? { ...row, [field]: value, name: mainText } : row);

    setLegDataArray(updatedRows);

    if (field == "symbol" || field == "optiontype" || field == "expiry") {
      if (field != "optiontype") {
        instrumentTokenFilter(id, value, field,);
      }
    }
  };

  const getMarketSegments = (exchange: string) => {
    return exchange === 'NseFO' ? mktsegment_FO : exchange === 'NseCM' ? mktsegment_CM : [];
  };

  const getcontracttypes = (mktsegment: string) => {
    return mktsegment === 'FUT' ? contracttype_F : mktsegment === 'OPT' ? contracttype_O : mktsegment === 'EQ' ? symbolEQ : [];
  };

  const getsymbol = (contracttypeOrSegment: string) => {
    if (contracttypeOrSegment === 'EQ') {
      return symbolEQ.sort((a, b) => a.localeCompare(b)); // Sort alphabetically
    }
    return (contracttypeOrSegment === 'FUTIDX' || contracttypeOrSegment === 'OPTIDX')
      ? symbolSTK.sort((a, b) => a.localeCompare(b)) // Sort alphabetically
      : (contracttypeOrSegment === 'FUTSTK' || contracttypeOrSegment === 'OPTSTK')
        ? symbolIDX.sort((a, b) => a.localeCompare(b)) // Sort alphabetically
        : [];
  };
  

  const ButtonClick = async (legDataArray: LegData[]) => {

    const segmentCount = legDataArray.reduce((acc, leg) => {
      acc[leg.mktsegment] = (acc[leg.mktsegment] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    const maxSegment = Object.keys(segmentCount).reduce((a, b) =>
      segmentCount[a] > segmentCount[b] ? a : b
    );

    let filteredLegDataArray = legDataArray;

    for (const leg of filteredLegDataArray) {
      if (!leg.buysellorder) {
        alert(`Please fill in buysellorder for Token ${leg.tokennumber}`);
        return;
      }
      if (!leg.lots) {
        alert(`Please fill in lots for Token ${leg.tokennumber}`);
        return;
      }
    }

    if (mainText === "") {
      alert("Please enter the Leg Name");
      return;
    }
    const requestData = {
      legs: filteredLegDataArray,
      legName: mainText,
    };

    if (filteredLegDataArray.length > 1) {
      filteredLegDataArray = filteredLegDataArray.map(leg => ({
        ...leg,
        BuyOrderSellOrder: "ItsBoth"
      }));
    }
    else {
      filteredLegDataArray = filteredLegDataArray.map(leg => ({
        ...leg,
        BuyOrderSellOrder: leg.buysellorder
      }));
    }

    setExistingNames((prevNames) => {
    if (prevNames.includes(mainText)) {
      alert("Name already exists");
      return prevNames;
    } else {
      setFormData(filteredLegDataArray, mainText);
      return [...prevNames, mainText];
    }
  });
  };

  const handleSocket = async (Token: any[]) => {

    const accessT = "zgNd4LMGlLc1LhbPAnwjaZIQa6w3wZj4";

    try {
      let res = await axios.get('https://api.insperoninvestmentsolutions.com/api/getLiveDataIndexs', {
        params: {
          Token,
          access_token: accessT
        }
      });

    } catch (error) {
      console.log("Error in fetching data:", error);
    }
  };
  const getBuySellType = () => {
    return call_BS
  }
  const getoptiontype = (contracttype: any) => {

    switch (contracttype) {
      case 'OPTIDX':
        return [{ key: 1, text: 'CE', value: 'CE' }, { key: 2, text: 'PE', value: 'PE' }];
      case 'OPTSTK':
        return [{ key: 1, text: 'CE', value: 'CE' }, { key: 2, text: 'PE', value: 'PE' }];
      default:
        return []
    }
  }

  const instrumentTokenFilter_OnOptionexpiry = (id: number, expiry: string) => {

    const data = legDataArray.find(x => x.id === id);
    let response = [];

    if (data?.mktsegment === "OPT") {

      response = instrumentList
        .filter(x =>
          x.strike == data.strikeprice &&
          x.name == data.symbol &&
          x.instrument_type == data.optiontype &&
          x.expiry === expiry
        )
    }

    if (data?.mktsegment === "FUT") {
      response = instrumentList
        .filter(x =>
          x.name == data.symbol &&
          x.instrument_type == "FUT" &&
          x.expiry === expiry
        )
    }

    if (response.length > 0) {
      const token = response.length > 0 ? response[0].instrument_token : null;
      const code = response.length > 0 ? response[0].exchange_token : null;
      const description = response.length > 0 ? response[0].name : null;
      const expiry = response.length > 0 ? response[0].expiry : null;
      const updatedLegDataArray = legDataArray.map(leg => {
        if (leg.id === id) {
          return { ...leg, tokennumber: token, code: code, description: description, expiry: expiry };
        }
        return leg;
      });
      setLegDataArray(updatedLegDataArray);
    }
  }

  const instrumentTokenFilter = (id: number, symbol: string, field: string,) => {

    const data = legDataArray.find(x => x.id === id);
    let response = [];

    if (data && instrumentList.length > 0 && symbol !== "") {// Ensure there's at least one item in the array
      if (field === "symbol") {
        data.symbol = symbol;
      } else if (field === "optiontype") {
        data.optiontype = symbol;
      } else if (field === "strikeprice") {
        // data.strikeprice = symbol;
      }


      if (symbol !== "" && data.mktsegment == "FUT") {
        response = instrumentList
          .filter(x =>
            x.name == symbol &&
            x.instrument_type == data.mktsegment &&
            x.segment === "NFO-FUT"
          )
      }

      if (symbol !== "" && data.mktsegment == "OPT") {

        response = instrumentList
          .filter(x =>
            x.strike == data.strikeprice &&
            x.name == data.symbol &&
            x.instrument_type == data.optiontype &&

            x.expiry === data.expiry
          )
      }

      if (symbol !== "" && data.mktsegment == "EQ") {

        response = instrumentList
          .filter(x =>
            x.tradingsymbol == symbol &&
            x.instrument_type == data.mktsegment &&
            x.segment === "NSE"
          )
      }
    }

    if (response.length > 0) {
      const token = response.length > 0 ? response[0].instrument_token : null;
      const code = response.length > 0 ? response[0].exchange_token : null;
      const description = response.length > 0 ? response[0].name : null;
      const expiry = response.length > 0 ? response[0].expiry : null;
      const updatedLegDataArray = legDataArray.map(leg => {
        if (leg.id === id) {
          return { ...leg, tokennumber: token, code: code, description: description, expiry: expiry };
        }
        return leg;
      });
      setLegDataArray(updatedLegDataArray);
    }
  }

  const strikepriceFilter = (Type: string, id: number): void => {

    const data = legDataArray.find(x => x.id === id);
    if (data && instrumentList.length > 0 && (Type === "CE" || Type === "PE") && data.symbol !== "") {
      const response = instrumentList
        .filter(x =>
          x.exchange === "NFO" &&
          x.instrument_type === Type &&
          x.segment === "NFO-OPT" &&
          x.name === data.symbol
        )
        .map(x => x.strike)
        .filter((value, index, self) => self.indexOf(value) === index);

      setstrikeprices(prev => ({
        ...prev,
        [id]: response.length > 0 ? response : []
      }));
    } else {
      setstrikeprices(prev => ({
        ...prev,
        [id]: []
      }));
    }
  };

  const handleexpiryFilter = (id: number, price: string, resetexpiry: boolean = false): void => {
    const data = legDataArray.find(x => x.id === id);

    if (data && data.symbol !== "") {
      let response: string[] = [];

      if (data.mktsegment === "OPT") {
        response = instrumentList
          .filter(x =>
            x.exchange === "NFO" &&
            x.strike === price &&
            x.segment === "NFO-OPT" &&
            x.name === data.symbol &&
            x.instrument_type === data.optiontype
          )
          .map(x => x.expiry)
          .filter((value, index, self) => self.indexOf(value) === index);
      }

      if (data.mktsegment === "FUT") {
        response = instrumentList
          .filter(x =>
            x.name === data.symbol &&
            x.exchange === "NFO" &&
            x.segment === "NFO-FUT" &&
            x.instrument_type === "FUT"
          )
          .map(x => x.expiry)
          .filter((value, index, self) => self.indexOf(value) === index);
      }

      setexpiryOpt(prev => ({
        ...prev,
        [id]: response.length > 0 ? response : []
      }));

      if (resetexpiry) {
        setLegDataArray((prevArray) =>
          prevArray.map((leg) =>
            leg.id === id ? { ...leg, expiry: '' } : leg
          )
        );
      }
    } else {
      setexpiryOpt(prev => ({
        ...prev,
        [id]: []
      }));
    }
  };


  return (
    <Dialog open={show} onClose={onClose} sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '1500px' } }}
      PaperProps={{ sx: { top: 0, left: '50%', transform: 'translateX(-50%) translateY(50px)', position: 'absolute', zIndex: 1300 } }} >
      <DialogContent>
        <Grid container alignItems="center" spacing={2} direction="row">
          <Grid item>
            <Typography variant="body1">exchange:</Typography>
          </Grid>

          {/* TextField */}
          <Grid item xs={12} sm={4}>
            <TextField
              label="Name"
              value={mainText}
              onChange={(e) => setMainTextName(e.target.value)}
              fullWidth
              placeholder="Enter Name"
              margin="normal"
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>

        <div style={{
          marginTop: '20px',
          borderTop: '2px solid #000',
          borderRadius: '5px',
          width: '100%',
        }} />

        {/* Table for Leg Components */}
        <TableContainer style={{ margin: '20px 0px 0px 0px', height: '520px' }}>
          <Table>
            <TableBody>
              {legDataArray.map((leg, index) => (
                <TableRow key={leg.id}>
                  {/* Add New Leg */}

                  <TableCell style={{ width: '3%' }}>
                    <IconButton color="primary" onClick={() => addNewRow()}>
                      <AddCircle />
                    </IconButton>
                  </TableCell>

                  {/* exchange  */}
                  <TableCell style={{ width: '10%' }}>
                    <FormControl fullWidth>
                      <InputLabel>{leg.exchange == '' ? "exchange" : ""}</InputLabel>
                      <Select
                        style={{ height: '2.7em' }}
                        value={leg.exchange}
                        onChange={(e) => handleInputChange(leg.id, 'exchange', e.target.value)}
                      >
                        <MenuItem value="">Select Exchange</MenuItem>
                        {exchange.map((ex) => (
                          <MenuItem key={ex.key} value={ex.value}>
                            {ex.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>

                  {/* MKT Segment */}
                  <TableCell style={{ width: '10%' }}>
                    <FormControl fullWidth>
                      <InputLabel>{leg.mktsegment == '' ? "Market Seg." : ""}</InputLabel>
                      <Select style={{ height: '2.7em' }} value={leg.mktsegment}
                        onChange={(e) => handleInputChange(leg.id, 'mktsegment', e.target.value)}
                      >
                        <MenuItem value="">Select Market Segment</MenuItem>
                        {getMarketSegments(leg.exchange).map((mkt) => (
                          <MenuItem key={mkt.key} value={mkt.value}>{mkt.text}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>

                  {leg.mktsegment != "EQ" && (
                    <TableCell style={{ width: '9%' }}>
                      <FormControl fullWidth>
                        <InputLabel>
                          {leg.contracttype == '' ? "Contract Type" : ""}</InputLabel>
                        <Select style={{ height: '2.7em' }} value={leg.contracttype} onChange={(e) => handleInputChange(leg.id, 'contracttype', e.target.value)}>
                          <MenuItem value="">Select Contract Type</MenuItem>
                          {
                            getcontracttypes(leg.mktsegment).map((contract: any) => (
                              <MenuItem key={contract.key} value={contract.value}>{contract.text}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </TableCell>
                  )}

                  <TableCell style={{ width: '11%' }}>
                    <FormControl fullWidth>
                      {/* <InputLabel>{leg.symbol === '' ? "symbol" : ""}</InputLabel>
                      <Select
                        style={{ height: '2.7em' }}
                        value={leg.symbol}
                        onChange={(e) => {
                          const selectedsymbol = e.target.value;
                          if (leg.mktsegment === 'EQ' || leg.mktsegment === "FUT") {
                            handleInputChange(leg.id, 'symbol', selectedsymbol);
                          }
                          if (leg.mktsegment === "FUT") {
                            handleexpiryFilter(leg.id, e.target.value, true);
                          }
                          if (leg.mktsegment === 'OPT') {
                            handleInputChange(leg.id, 'symbol', selectedsymbol);
                            strikepriceFilter(leg.optiontype, leg.id);
                          }
                        }}
                      >
                        <MenuItem value="">Select Symbol</MenuItem>
                        {(leg.mktsegment === 'EQ' ? getsymbol('EQ') : getsymbol(leg.contracttype)).map((contract: any) => (
                          <MenuItem key={contract} value={contract}>{contract}</MenuItem>
                        ))}
                      </Select> */}


                      <Autocomplete
                        style={{ height: '2.7em' }}
                          options={leg.mktsegment === 'EQ' ? getsymbol('EQ') : getsymbol(leg.contracttype)}
                          renderOption={(props, option) => (
                            <Box style={{ height: '2.7em' }} component="li" {...props} key={option}>
                              
                                {option}
                              
                            </Box>
                          )}
                          sx={{ width: 150 }}
                          value={leg.symbol}
                          onChange={(e:any) => {
                           const selectedsymbol = e.target.innerHTML;
                           if (leg.mktsegment === 'EQ' || leg.mktsegment === "FUT") {
                             handleInputChange(leg.id, 'symbol', selectedsymbol);
                           }
                           if (leg.mktsegment === "FUT") {
                             handleexpiryFilter(leg.id, e.target.value, true);
                           }
                           if (leg.mktsegment === 'OPT') {
                             handleInputChange(leg.id, 'symbol', selectedsymbol);
                             strikepriceFilter(leg.optiontype, leg.id);
                           }
                          }}
                          renderInput={(params) => <TextField style={{ height: '2.7em' }} {...params} label="Select Symbol" />}
                        />
                    </FormControl>
                  </TableCell>

                  {/* Option Type */}
                  {leg.mktsegment === "OPT" && (
                    <TableCell style={{ width: '9%' }}>
                      <FormControl fullWidth>
                        <InputLabel>{leg.optiontype === '' ? "Option Type" : ""}</InputLabel>
                        <Select
                          style={{ height: '2.7em', padding: '9.5px 14px' }}
                          value={leg.optiontype}
                          onChange={(e) => {
                            handleInputChange(leg.id, 'optiontype', e.target.value);
                            strikepriceFilter(e.target.value, leg.id);
                          }}
                        >
                          <MenuItem value="">Select Option Type</MenuItem>
                          {getoptiontype(leg.contracttype).map((opt) => (
                            <MenuItem key={opt.key} value={opt.value}>
                              {opt.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  )}

                  {/* Strike Prie */}
                  {leg.mktsegment === "OPT" && (
                    <TableCell style={{ width: '9%' }}>
                      <FormControl fullWidth>
                        <Autocomplete
                        style={{ height: '2.7em' }}
                          options={strikeprices && Object.keys(strikeprices).length > 0 ? strikeprices[leg.id] : []}
                          renderOption={(props, option) => (
                            <Box style={{ height: '2.7em' }} component="li" {...props} key={option}>
                              
                                {option}
                              
                            </Box>
                          )}
                          sx={{ width: 150 }}
                          value={leg.strikeprice}
                          onChange={(e:any) => {
                            
                           handleInputChange(leg.id, 'strikeprice', e.target.innerHTML)
                           handleexpiryFilter(leg.id, e.target.innerHTML, true);
                          }}
                          renderInput={(params) => <TextField style={{ height: '2.7em' }} {...params} label="Select Strike" />}
                        />
                      </FormControl>
                    </TableCell>
                  )}

                  {(leg.mktsegment === "OPT" || leg.mktsegment === "FUT") && (
                    <TableCell style={{ width: '10%' }}>
                      <FormControl fullWidth>
                        <InputLabel>{leg.expiry === '' ? "Expiry" : ""}</InputLabel>
                        <Select
                          style={{ height: '2.7em', padding: '9.5px 14px' }}
                          value={leg.expiry || ''} // Set value to an empty string initially
                          onChange={(e) => {
                            handleInputChange(leg.id, 'expiry', e.target.value);
                            instrumentTokenFilter_OnOptionexpiry(leg.id, e.target.value);
                          }}
                        >
                          <MenuItem value="">Select Expiry</MenuItem>
                          {Array.isArray(expiryOpt[leg.id]) && expiryOpt[leg.id].length > 0 ? (
                            expiryOpt[leg.id].map((opt) => (
                              <MenuItem key={opt} value={opt}>
                                {opt}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="">No Expiry Options Available</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </TableCell>
                  )}

                  {/* Token Number */}

                  { <TableCell style={{ width: '11%' }}>
                    <TextField
                      label={leg.tokennumber == '' ? "Token Number" : ""}
                      type="number"
                      value={leg.tokennumber}
                      onChange={(e) => handleInputChange(leg.id, 'tokennumber', e.target.value)}

                    />
                  </TableCell> }

                  {/* code */}

                  {leg.mktsegment === "EQ" && (
                    <TableCell style={{ width: '11%' }}>
                      <TextField
                        label="code"
                        value={leg.code}
                        onChange={(e) => handleInputChange(leg.id, 'code', e.target.value)}
                        fullWidth
                      />
                    </TableCell>
                  )}

                  {/* description */}

                  {leg.mktsegment === "EQ" && (
                    <TableCell style={{ width: '11%' }}>
                      <TextField
                        label="description"
                        value={leg.description}
                        onChange={(e) => handleInputChange(leg.id, 'description', e.target.value)}
                        fullWidth
                      />
                    </TableCell>
                  )}

                  {/* Buy/Sell Order */}
                  <TableCell style={{ width: '9%' }}>
                    <FormControl fullWidth>
                      <InputLabel>{leg.buysellorder == '' ? "Buy/Sell" : ""}</InputLabel>
                      <Select
                        style={{ height: '2.7em' }}
                        value={leg.buysellorder}
                        onChange={(e) => handleInputChange(leg.id, 'buysellorder', e.target.value)}
                      >
                        <MenuItem value="">Select Buy/Sell</MenuItem>
                        {getBuySellType().map((bs) => (
                          <MenuItem key={bs.key} value={bs.value}>
                            {bs.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>

                  {/* lots */}
                  <TableCell style={{ width: '11%' }}>
                    <TextField
                      label={leg.lots == '' ? "lots" : ""}
                      value={leg.lots}
                      onChange={(e) => handleInputChange(leg.id, 'lots', e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    {legDataArray[1] && (
                      <IconButton onClick={() => deleteRowByIndex(index)} color="error" aria-label="delete">
                        <Delete />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Save Button */}
        <DialogActions>
          <Button variant="outlined" onClick={() => ButtonClick(legDataArray)} color="primary">Save</Button>
          <Button variant="outlined" onClick={onClose} color="secondary">Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ModalPopup;
